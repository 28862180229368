import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "components/CoreWeb/CreateServiceStep1.jsx";
import CreateServiceStep2 from "components/CoreWeb/CreateServiceStep2.jsx";
import CreateServiceStep4 from "components/CoreWeb/CreateServiceStep4.jsx";
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";

import final from "@material-ui/icons/VerifiedUser";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import { hardCodedOrgId } from "variables/project.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ServiceView from "../../components/CoreWeb/ServiceView.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class CreateService extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <ServiceView></ServiceView>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateService));
