import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import CardFooter from "components/Card/CardFooter.jsx";
import Place from "@material-ui/icons/Place";
import Table from "components/Table/Table.jsx";
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import FileUpload from "components/CustomUpload/FileUpload.jsx";
import Info from "@material-ui/icons/Info";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";
import LocationOn from "@material-ui/icons/LocationOn";
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Accordion from "components/Accordion/Accordion.jsx";
import ReactTable from "react-table";
import StarRatingComponent from "react-star-rating-component";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import priceImage3 from "assets/img/qrcodesample.png";
import { dataTable3 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import { withRouter } from "react-router";

import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import { hardCodedOrgId } from "variables/project.jsx";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";
import { APIURL } from "../../variables/project";
import { rows } from "variables/coreWeb.jsx";
import { connect } from "react-redux";
import { getActiveOrg } from "../../variables/coreWeb";

const postQrSo = function()
{

}

const generateQrSo = function(callApi, qrSoOptions) {
  return async function(dispatch, getState) {
    console.log(
      "-------generateQrSo:" + JSON.stringify(qrSoOptions)
    );
    await postQrSo(callApi, qrSoOptions);
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};


class WorkModeSfc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Services"}
          afterActionHandler={actions.cw.GOT_ALL_SERVICES_BY_ORG_ID}
          data={{
            orgId: getActiveOrg()
          }}
        />
        <CoreApiReq
          endpointToCall={"Read_All_ServiceFulfilmentConfigs_By_Org_ID"}
          afterActionHandler={actions.cw.GOT_ALL_SFCS_BY_ORG_ID}
          data={{
            orgId: getActiveOrg()
          }}
        />
        <h4>Work Mode</h4>
        <Card>
          <CardBody>
            <h4>Campaign Selection</h4>
            <ReduxCoreSelect
            options={rows(this.props.serviceCompleteList, "name", "serviceId")} 
            placeholder={"Select your service type"}
            fieldIdentifier={'chosenService'}></ReduxCoreSelect>
          </CardBody>
        </Card>
        {this.props.chosenService ? <Card>
          <CardBody>
            <h4>Loyalty Configuration</h4>
            <ReduxCoreSelect
            options={rows(this.props.orgServiceFulfilmentConfigCompleteList, "name", "serviceFulfilmentConfigId")}
            placeholder={"Select your loyalty type"}
            fieldIdentifier={'chosenSfc'}
            ></ReduxCoreSelect>
          </CardBody>
        </Card> : ''}
        {this.props.chosenService && this.props.chosenSfc && <h4>Actions</h4>}
        {this.props.chosenService && this.props.chosenSfc ? <Card>
          <CardBody>
            <GridContainer justify={"center"}>
              <GridItem>
                <Button color="rose">Generate Code</Button>
                
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card> : ''}
        {this.props.chosenService && this.props.chosenSfc && this.props.generatedQrCodeUrl && <h4>Generated QR</h4>}
        {this.props.chosenService && this.props.chosenSfc && this.props.generatedQrCodeUrl ? <Card>
          <CardBody>
            <h4>QR Code</h4>
            <GridContainer justify="center">
              <GridItem>
              <Button color="rose" onClick={() => { alert("Coming soon") }}>Share</Button>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <img height={400} src={this.props.qrCodeUrl}></img>
                
              </GridItem>
            </GridContainer>
            
          </CardBody>
        </Card> : ''}
   
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    qrCodeUrl: APIURL,
    chosenService: state.pwReducer.chosenService,
    chosenSfc: state.pwReducer.chosenSfc,
    serviceCompleteList: state.pwReducer.serviceCompleteList || [],
    orgServiceFulfilmentConfigCompleteList: state.pwReducer.chosenService && state.pwReducer.chosenService > 0 ? (state.pwReducer.orgServiceFulfilmentConfigCompleteList || []).filter(x => x.serviceId === state.pwReducer.chosenService) : []
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(WorkModeSfc));
