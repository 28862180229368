import initialState from "reducers/initialState";
import * as actions from "actions/projectActionTypes";
import axios from "axios";
import { APIURL } from "variables/project";
import {
  set,
  stringMatch,
  genericAddList,
  genericRmList,
  genericHandleListItem,
  safeFindMetaDataString,
  safeFindMetaDataNumbers,
  changeUrl,
} from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";

export default function handle(state = initialState, action) {
  console.log("INITIAL");
  console.log(initialState);
  let newState;
  var color, message;
  console.log("pwReducer: " + action.type);
  console.log(action);
  switch (action.type) {
    case actions.cw.API_RESP_CREATE:
      console.log("API_RESP_CREATE");
      if (action.payload.func_status == 1) {
        alert("Created");
      } else {
        alert("func_status" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_RESOURCE_CREATE:
      console.log("API_RESOURCE_CREATE");
      if (action.payload.func_status == 1) {
        alert("Resources Created");
        changeUrl(paths.resourceList);
      } else {
        alert("func_status Resources" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.READ_SELFPROFILE:
      console.log("READ_SELFPROFILE Action");
      if (action.payload.func_status === 3) {
        changeUrl(paths.logout);
      }
      return { ...state, selfProfile: action.payload };

    case actions.cw.API_SERVICE_CREATE:
      console.log("API_SERVICE_CREATE");
      if (action.payload.func_status == 1) {
        alert("Services Created");
        changeUrl(paths.serviceList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_ANIMAL_CREATE:
      console.log("API_ANIMAL_CREATE");
      if (action.payload.func_status == 1) {
        alert("Patient Created");
        changeUrl(paths.animalList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_CUSTOMER_CREATE:
      console.log("API_CUSTOMER_CREATE");
      if (action.payload.func_status == 1) {
        alert("Customer Created");
        changeUrl(paths.customerList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_PRODUCT_CREATE:
      console.log("API_PRODUCT_CREATE");
      if (action.payload.func_status == 1) {
        alert("Product Created");
        changeUrl(paths.productList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_MEMBER_CREATE:
      console.log("API_MEMBER_CREATE");
      if (action.payload.func_status == 1) {
        alert("Member Created");
      } else {
        alert("func_status Service" + action.payload.func_status);
      }
      return { ...state };

    case actions.cw.API_CONTACT_DELETE:
      console.log("API_CONTACT_DELETE");
      if (action.payload.func_status == 1) {
        alert("Contact Deleted");
        changeUrl(paths.customerList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_ANIMAL_DELETE:
      console.log("API_ANIMAL_DELETE");
      if (action.payload.func_status == 1) {
        alert("Patient Deleted");
        changeUrl(paths.animalList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }
      return { ...state };

    case actions.cw.API_SERVICE_DELETE:
      console.log("API_SERVICE_DELETE");
      if (action.payload.func_status == 1) {
        alert("Service Deleted");
        changeUrl(paths.serviceList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_PRODUCT_DELETE:
      console.log("API_PRODUCT_DELETE");
      if (action.payload.func_status == 1) {
        alert("Product Deleted");
        changeUrl(paths.productList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.API_RESOURCE_DELETE:
      console.log("API_RESOURCE_DELETE");
      if (action.payload.func_status == 1) {
        alert("Resource Deleted");
        changeUrl(paths.resourceList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

      return { ...state };

    case actions.cw.GOT_ALL_ORG_RESOURCES:
      console.log("GOT_ALL_ORG_RESOURCES");
      var resourceCompleteListOptions = [];
      action.payload.resourceCompleteList.map((x) => {
        resourceCompleteListOptions.push({ text: x.resourceShortName });
      });
      return {
        ...state,
        resourceCompleteListOptions: resourceCompleteListOptions,
        resourceCompleteList: action.payload.resourceCompleteList,
      };
    case actions.cw.GOT_ORGDETAILS:
      console.log("GOT_ORGDETAILS");
      return { ...state, orgDetails: action.payload };
    case actions.cw.UPREDUXCUSTOMINPUT:
      console.log("UPREDUXCUSTOMINPUT");
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      return newState;
    case actions.cw.UPREDUXSELECTINPUT:
      console.log("UPREDUXSELECTINPUT");
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      return newState;
    case actions.cw.UPREDUXCOREICONINPUTSINGLE:
      console.log("UPREDUXCOREICONINPUTSINGLE");
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = [action.payload.value];
      return newState;

    case actions.cw.UPREDUXCOREICONINPUT:
      console.log("UPREDUXCOREICONINPUT");
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = genericHandleListItem(
        newState[action.payload.fieldIdentifier],
        action.payload.value
      );
      return newState;

    case actions.cw.UPREDUXGROUPBUTTONSELECTINPUT:
      console.log("UPREDUXGROUPBUTTONSELECTINPUT");
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = genericHandleListItem(
        newState[action.payload.fieldIdentifier],
        action.payload.value
      );
      return newState;
    case actions.cw.MANDSTAFFSELECTCHANGE:
      console.log("MANDSTAFFSELECTCHANGE");
      newState = { ...state };
      set(newState, action.payload.fieldIdentifier, action.payload.value);
      var mandatoryStaff = [];
      for (var i = 0; i < action.payload.value; i++) {
        mandatoryStaff.push({
          type: 1,
          staffMember: undefined,
          duration: undefined,
          salesPrice: undefined,
        });
      }
      newState.arrayOfMandatoryStaff = mandatoryStaff;
      return newState;

    case actions.cw.GOT_SELFPROFILE:
      newState = { ...state };
      console.log("GOT_SELFPROFILE Action");
      return { ...newState, selfProfile: action.payload };

    case actions.cw.GOT_ORGLIST:
      newState = { ...state };
      console.log("GOT_ORGLIST Action");
      return { ...newState, orgList: action.payload.completeOrgList };

    case actions.cw.GOT_SERVICEORDERS_SELF:
      newState = { ...state };
      console.log("GOT_SERVICEORDERS_SELF Action");
      return {
        ...newState,
        selfServiceOrderList: action.payload.serviceOrderList,
      };

    case actions.cw.GOT_SERVICE_DETAILS:
      newState = { ...state };
      console.log("GOT_SERVICE_DETAILS Action");
      return { ...newState, serviceDetails: action.payload };

    case actions.cw.GOT_PRODUCT_DETAILS:
      newState = { ...state };
      console.log("GOT_PRODUCT_DETAILS Action");
      return { ...newState, productDetails: action.payload };

    case actions.cw.GOT_SERVICEFULFILMENTS_SELF:
      newState = { ...state };
      console.log("GOT_SERVICEFULFILMENTS_SELF Action");
      return {
        ...newState,
        selfServiceFulfilmentConfigCompleteList:
          action.payload.serviceFulfilmentConfigCompleteList,
      };

    case actions.cw.GOT_ALLORGSERVICEORDERS:
      newState = { ...state };
      console.log("GOT_ALLORGSERVICEORDERS Action");
      return {
        ...newState,
        orgServiceOrderList: action.payload.serviceOrderList,
      };

    case actions.cw.GOT_AVAILABLESTARTTIMES:
      newState = { ...state };
      console.log("GOT_AVAILABLESTARTTIMES Action");
      return { ...newState, availableStartTimes: action.payload.searchResults };

    case actions.cw.GOT_SERVICEORDERS_ORG:
      newState = { ...state };
      console.log("GOT_SERVICEORDERS_ORG Action");
      return {
        ...newState,
        serviceOrderListOrg: action.payload.serviceOrderList,
      };

    case actions.cw.GOT_SERVICEFULFILMENTCONFIG:
      newState = { ...state };
      console.log("GOT_SERVICEFULFILMENTCONFIG Action");
      return { ...newState, serviceFulfilmentConfig: action.payload };
    case actions.cw.GOT_ORGDETAILSLIMITED:
      var orgDetails = {
        ...action.payload,
      };
      for (var i = 0; i < action.payload.metaDataList.length; i++) {
        var currentItem = action.payload.metaDataList[i];
        orgDetails[currentItem.metaDataIdentifier] = currentItem.metaData;
      }

      return { ...state, orgDetails: orgDetails };
    case actions.cw.GOT_ALL_ORG_ANIMALS_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_ANIMALS_BY_ORG_ID Action");
      return { ...newState, animalList: action.payload.animalList };

    case actions.cw.GOT_ALL_ORG_CONTACTS_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_CONTACTS_BY_ORG_ID Action");
      return { ...newState, contactList: action.payload.contactList };

    case actions.cw.GOT_ALL_ORG_PRODUCTS:
      newState = { ...state };
      console.log("GOT_ALL_ORG_PRODUCTS Action");
      return { ...newState, productList: action.payload.productList };

    case actions.cw.GOT_ALL_ORG_RESOURCES_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_RESOURCES_BY_ORG_ID Action");
      return {
        ...newState,
        resourceCompleteList: action.payload.resourceCompleteList,
      };

    case actions.cw.GOT_ALL_SERVICES_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_SERVICES_BY_ORG_ID Action");
      return {
        ...newState,
        serviceCompleteList: action.payload.serviceCompleteList,
      };

    case actions.cw.GOT_ALL_SFCS_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_SFCS_BY_ORG_ID Action");
      return {
        ...newState,
        orgServiceFulfilmentConfigCompleteList:
          action.payload.serviceFulfilmentConfigCompleteList,
      };

    case actions.cw.GOT_ALL_ORG_INVOICES_BY_ORG_ID:
      newState = { ...state };
      console.log("GOT_ALL_ORG_INVOICES_BY_ORG_ID Action");
      return {
        ...newState,
        listOfInvoices: action.payload.listOfInvoices,
      };

    case actions.cw.NOTI_CREATESUCCESS:
      newState = { ...state };
      console.log("NOTI_CREATESUCCESS Action");
      return { ...newState, showOnCreateSuccess: true };
    case actions.cw.PAGE_RELOAD:
      newState = { ...state };
      console.log("PAGE_RELOAD Action");
      window.location.reload();
      return { ...newState };
    case actions.cw.GOT_ALL_ORG_USERS:
      newState = { ...state };
      console.log("GOT_ALL_ORG_USERS Action");
      return { ...newState, userList: action.payload.userList };

    case actions.cw.SET_STATE_VAL:
      newState = { ...state };
      newState[action.payload.fieldIdentifier] = action.payload.value;
      console.log("SET_STATE_VAL Action");
      return newState;

    case actions.cw.GOT_ANIMAL_DETAILS:
      newState = { ...state };
      console.log("GOT_ANIMAL_DETAILS Action");
      return { ...newState, animalDetails: action.payload };

    case actions.cw.GOT_RESOURCE_DETAILS:
      newState = { ...state };
      console.log("GOT_RESOURCE_DETAILS Action");
      return { ...newState, resourceDetails: action.payload };

    case actions.cw.GOT_CONTACT_DETAILS:
      newState = { ...state };
      console.log("GOT_CONTACT_DETAILS Action");
      return { ...newState, contactDetails: action.payload };
    case actions.cw.GOT_SERVICEFULFILMENT_COMPLETE:
      newState = { ...state };
      console.log("GOT_SERVICEFULFILMENT_COMPLETE Action");
      var mandatoryStaff = action.payload.serviceFulfilmentConfigResourceMapList.filter(
        (x) =>
          x.serviceFulfilmentResourceConfigRelationship === 2 &&
          x.serviceFulfilmentResourceConfigRequiredOptional == 3
      );
      var optionalStaff = action.payload.serviceFulfilmentConfigResourceMapList.filter(
        (x) =>
          x.serviceFulfilmentResourceConfigRelationship === 2 &&
          x.serviceFulfilmentResourceConfigRequiredOptional == 2
      );
      var valsToAppend = {};
      for (var i = 0; i < mandatoryStaff.length; i++) {
        valsToAppend["mandatoryStaffConfigResourceSelect" + i] =
          mandatoryStaff[i].resourceId;
        valsToAppend["mandatoryStaffConfigDuration" + i] =
          mandatoryStaff[i].durationMilliseconds;
        valsToAppend["mandatoryStaffConfigSalesPrice" + i] =
          mandatoryStaff[i].monetaryAmount;
      }
      for (var i = 0; i < optionalStaff.length; i++) {
        valsToAppend["optionalStaffConfigResourceSelect" + i] =
          optionalStaff[i].resourceId;
        valsToAppend["optionalStaffConfigDuration" + i] =
          optionalStaff[i].durationMilliseconds;
        valsToAppend["optionalStaffConfigSalesPrice" + i] =
          optionalStaff[i].monetaryAmount;
      }
      for (var i = 0; i < action.payload.metaDataList.length; i++) {
        action.payload[action.payload.metaDataList[i].metaDataIdentifier] =
          action.payload.metaDataList[i].metaData;
      }
      return {
        ...newState,
        serviceFulfilmentConfig: action.payload,
        mandatoryStaff: mandatoryStaff.length,
        ...valsToAppend,
      };
    case actions.cw.API_CUSTOMER_UPDATE:
      newState = { ...state };
      console.log("API_CUSTOMER_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Customer successfully modified";
        setTimeout(function() {
          changeUrl(paths.customerList);
        }, 2500);
      } else {
        color = "danger";
        message = "An error occurred";
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_ANIMAL_UPDATE:
      newState = { ...state };
      console.log("API_ANIMAL_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Patient successfully modified";
        setTimeout(function() {
          changeUrl(paths.animalList);
        }, 2500);
      } else {
        color = "danger";
        message = "An error occurred";
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };

    case actions.cw.API_SFC_UPDATE:
      newState = { ...state };
      console.log("API_SFC_UPDATE");
      if (action.payload.func_status == 1) {
        color = "success";
        message = "Pricing successfully modified";
        setTimeout(function() {
          changeUrl(paths.serviceList);
        }, 2500);
      } else {
        color = "danger";
        message = "An error occurred";
      }
      return {
        ...newState,
        toastMessage: message,
        showOnCreateSuccess: true,
        toastColor: color,
      };
    case actions.cw.API_CUSTOMER_UPDATE:
      console.log("API_CUSTOMER_UPDATE");
      if (action.payload.func_status == 1) {
        alert("Customer Updated");
        changeUrl(paths.customerList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

    case actions.cw.API_ANIMAL_UPDATE:
      console.log("API_ANIMAL_UPDATE");
      if (action.payload.func_status == 1) {
        alert("Patient Updated");
        changeUrl(paths.animalList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

    case actions.cw.API_SERVICE_UPDATE:
      console.log("API_ANIMAL_UPDATE");
      if (action.payload.func_status == 1) {
        alert("Service Updated");
        changeUrl(paths.serviceList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

    case actions.cw.API_PRODUCT_UPDATE:
      console.log("API_PRODUCT_UPDATE");
      if (action.payload.func_status == 1) {
        alert("Product Updated");
        changeUrl(paths.productList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

    case actions.cw.API_RESOURCE_UPDATE:
      console.log("API_RESOURCE_UPDATE");
      if (action.payload.func_status == 1) {
        alert("Resource Updated");
        changeUrl(paths.resourceList);
      } else {
        alert("func_status Service" + action.payload.func_status);
      }

    //ProjectWeb

    case actions.pw.GOT_SELFPROFILE:
      newState = {};
      newState.sellRepProfileString =
        action.payload.metaDataList.find((element) =>
          stringMatch(element.metaDataIdentifier, "mainProfileDesc")
        ).metaData || "";

      var count = 1;
      var found = true;
      newState.sellRepProfiles = [];
      while (found) {
        var allProfilesForNum =
          action.payload.metaDataList.filter((element) =>
            stringMatch(element.metaDataGroupIdentifier, "srprofile" + count)
          ) || [];
        if (allProfilesForNum.length > 0) {
          var addMe = {};
          addMe.title =
            allProfilesForNum.find((element) =>
              stringMatch(element.metaDataIdentifier, "srProfileTitle")
            ).metaData || "";
          addMe.type =
            allProfilesForNum.find((element) =>
              stringMatch(element.metaDataIdentifier, "srProfileType")
            ).metaData || "";
          newState.sellRepProfiles.push(addMe);
        } else {
          found = false;
        }
        count++;
      }
      return {
        ...state,
        sellRepProfileString: newState.sellRepProfileString,
        sellRepProfiles: newState.sellRepProfiles,
      };

      console.log("GOT_SELFPROFILE Action");
      return newState;

    case actions.pw.GOT_ORGDETAILSSELLREPS:
      var orgDetails = {
        ...action.payload,
      };
      for (var i = 0; i < action.payload.metaDataList.length; i++) {
        var currentItem = action.payload.metaDataList[i];
        orgDetails[currentItem.metaDataIdentifier] = currentItem.metaData;
      }

      return { ...state, orgDetails: orgDetails };
    case actions.pw.SET_DEFAULT_CAMPAIGNSETTINGS:
      var nextState = {
        ...state,
        campaignSummary: "Boobs",
        campaignName: "Test",
        campaignLoc: [1, 2],
        productOrService: [1],
        campaignProdOrServName: "Putain",
        campaignProdOrServRef: "Pooop",
        campaignProdOrServDesc: "Looooooool",
      };
      return nextState;

    case actions.pw.GOT_CAMPAIGNSETTINGS:
      var nextState = {
        ...state,
        campaignSummary: "",
        campaignName: action.payload.name,
        campaignLoc: [1, 2],
        productOrService: [1],
        campaignProdOrServName: "Putain",
        campaignProdOrServRef: "Pooop",
        campaignProdOrServDesc: "Looooooool",
        campaignIsActive: action.payload.isActive,
        campaignOrg: action.payload.orgId,
        campaignType: [action.payload.serviceId],
        sellRepProfileYearsExp: [2],
        pwReducerContactsProvided: [1],
        pwReducerTeritory: [1],
        dealSize: [1],
        salesCycle: [1],
        repPay: [1],
        sellRepProfileCustTypes: [1],
        comissionPerc: 10,
        whyWork: "Dont work here",
        typeMatch: "We like motivated people",
        trainingProv: "None provided",
        campaignAgreeDisagree: 3,
      };
      return nextState;

    case actions.pw.GOT_CANDIDATE_PROFILE:
      var yearsExperience = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "yearsExperience";
        })
        .metaData.split(",")
        .map(Number);

      var howToSell = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "howToSell";
        })
        .metaData.split(",")
        .map(Number);

      var customerTypes = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "customerTypes";
        })
        .metaData.split(",")
        .map(Number);

      var yesNoOption = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "yesNoOption";
        })
        .metaData.split(",")
        .map(Number);

      var numberOfEmployees = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "numberOfEmployees";
        })
        .metaData.split(",")
        .map(Number);

      var employeeSeniority = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "employeeSeniority";
        })
        .metaData.split(",")
        .map(Number);

      var campaignLocationChoice = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "campaignLocationChoice";
        })
        .metaData.split(",")
        .map(Number);

      var considerationOptions = action.payload.metaDataList
        .find(function(element) {
          return element.metaDataIdentifier == "considerationOptions";
        })
        .metaData.split(",")
        .map(Number);

      var profileLookingFor = action.payload.metaDataList.find(function(
        element
      ) {
        return element.metaDataIdentifier == "profileLookingFor";
      }).metaData;

      var profileMilestones = action.payload.metaDataList.find(function(
        element
      ) {
        return element.metaDataIdentifier == "profileMilestones";
      }).metaData;

      var profileJoinFactors = action.payload.metaDataList.find(function(
        element
      ) {
        return element.metaDataIdentifier == "profileJoinFactors";
      }).metaData;

      var nextState = {
        ...state,
        profileName: action.payload.name,
        campaignType: action.payload.serviceId,
        yearsExperience: yearsExperience,
        howToSell: howToSell,
        customerTypes: customerTypes,
        yesNoOption: yesNoOption,
        numberOfEmployees: numberOfEmployees,
        employeeSeniority: employeeSeniority,
        campaignLocationChoice: campaignLocationChoice,
        considerationOptions: considerationOptions,
        profileLookingFor: profileLookingFor,
        profileMilestones: profileMilestones,
        profileJoinFactors: profileJoinFactors,
      };
      return nextState;

    default:
      return state;
  }
}
