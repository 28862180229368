import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "./CreateServiceStep1.jsx";
import CreateServiceStep2 from "./CreateServiceStep2.jsx";
import CreateServiceStep4 from "./CreateServiceStep4.jsx";
import ComingSoonStep from "./ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import PrePaymentRequiredSelect from "components/CoreWeb/PrePaymentRequiredSelect";
import final from "@material-ui/icons/VerifiedUser";
import MandatoryStaffSelect from "components/CoreWeb/MandatoryStaffSelect";
import OptionalStaffSelect from "components/CoreWeb/OptionalStaffSelect";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SelectIsActive from "components/CoreWeb/SelectIsActive";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hardCodedOrgId } from "variables/project.jsx";
import { changeUrl, gridFix, viewButton } from "variables/coreWeb.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ReactTable from "react-table";
import { updateService } from "variables/coreRequests.jsx";
import { createServices } from "../../variables/coreCreateRequests.jsx";
import { updateServiceWithDispatch } from "../../variables/thunks.jsx";
import { paths } from "variables/projPaths.jsx";
import ServicePictureUpload from "../CustomUpload/ServicePictureUpload.jsx";
import { uploadFile } from "../../variables/coreRequests.jsx";
import { getMostRecentFileFromList } from "variables/coreWeb.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { useHistory } from "react-router-dom";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { getActiveOrg } from "../../variables/coreWeb.jsx";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
  gridFix: { ...gridFix },
};

const ServiceView = function(props) {
  const history = useHistory();
  const tabContent = function(classes) {
    var resp = [];
    resp.push({
      tabButton: "Options",
      tabIcon: ShoppingCart,
      tabContent: (
        <div>
          {props.match && props.match.params.serviceId != undefined ? (
            <div>
              {/*<h4>Service Image</h4>
              <Card>
                <CardBody>
                <ServicePictureUpload
                  imagePreviewUrl={this.props.imagePreviewUrl}
                  handleImageChange={file => {
                    this.setState({ fileSelected: file });
                  }}
            />
            {this.state.fileSelected ? (
              <GridContainer justify="center">
                <GridItem>
                <Button
                onClick={() => {
                  uploadFile(
                    [this.state.fileSelected],
                    [hardCodedOrgId,this.props.serviceDetails.serviceId],
                    "UploadServiceImage"
                  );
                }}
                color="rose"
              >
                Upload
              </Button>
                </GridItem>
              </GridContainer>
              
            ) : (
              ""
            )}
                </CardBody>
            </Card>*/}
            </div>
          ) : (
            ""
          )}
          <h4>Campaign Details</h4>

          <CreateServiceStep1 />
          {props.match && props.match.params.serviceId != undefined ? (
            <GridContainer justify="center" className={classes.gridFix}>
              <GridItem>
                <Button
                  color="rose"
                  onClick={() => {
                    props.dispatch(
                      updateServiceWithDispatch(
                        props.callApi,
                        props.serviceDetails
                      )
                    );
                  }}
                >
                  Update
                </Button>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer justify="center" className={classes.gridFix}>
              <GridItem>
                <Button
                  color="rose"
                  onClick={() => {
                    createServices(props.callApi, props.serviceDetails);
                  }}
                >
                  Create Campaign
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </div>
      ),
    });
    if (props.match && props.match.params.serviceId != undefined) {
      resp.push({
        tabButton: "Loyalty",
        tabIcon: Price,

        tabContent: (
          <div>
            <GridContainer className={classes.gridFix}>
              <GridItem>
                <Button
                  onClick={() => {
                    history.push(
                      paths.pricingCreate + "/" + props.match.params.serviceId
                    );
                  }}
                  color="rose"
                >
                  Add New Loyalty Settings
                </Button>
              </GridItem>
            </GridContainer>
            <h4>Loyalty Options</h4>
            <Card>
              <CardBody>
                <ReactTable
                  data={(
                    props.serviceDetails.serviceFulfilmentConfigCompleteList ||
                    []
                  ).map((prop, key) => {
                    return {
                      name: prop.name,
                      isActive: prop.isActive === 2 ? "Active" : "Inactive",
                      view: viewButton(function() {
                        history.push(
                          paths.pricingView +
                            "/" +
                            prop.orgId +
                            "/" +
                            prop.serviceId +
                            "/" +
                            prop.serviceFulfilmentConfigId
                        );
                      }, classes),
                    };
                  })}
                  filterable
                  columns={[
                    {
                      Header: "Loyalty Description",
                      accessor: "name",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Is Active",
                      accessor: "isActive",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Actions",
                      accessor: "view",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </div>
        ),
      });
      resp.push({
        tabButton: "Location",
        tabIcon: LocationOn,
        tabContent: (
          <Card>
            <CardBody>
              <ComingSoonStep />
            </CardBody>
          </Card>
        ),
      });
    }
    return resp;
  };

  const { classes } = props;
  return (
    <div>
      {props.match && props.match.params.serviceId != undefined ? (
        <CoreApiReq
          endpointToCall={"Read_Service"}
          afterActionHandler={actions.cw.GOT_SERVICE_DETAILS}
          data={{
            orgId: getActiveOrg(),
            serviceId: props.match.params.serviceId,
          }}
        />
      ) : (
        ""
      )}
      <NavPills color="rose" alignCenter tabs={tabContent(classes)} />
    </div>
  );
};

function mapStateToProps(state) {
  var activeFile = null;
  if (
    state.pwReducer.serviceDetails &&
    state.pwReducer.serviceDetails.fileList &&
    state.pwReducer.serviceDetails.fileList.length > 0
  ) {
    activeFile = getMostRecentFileFromList(
      state.pwReducer.serviceDetails.fileList
    );
  }
  return {
    serviceDetails: state.pwReducer.serviceDetails || {},
    activeFileUrl:
      activeFile != null
        ? "http://uploads.vetgeo.com/" +
          activeFile.fileId +
          "" +
          activeFile.fileExtension
        : null,
  };
  // return {
  //   serviceDetails: state.pwReducer.serviceDetails || {}
  // };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ServiceView));
