import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import Check from "@material-ui/icons/Check";
import { connect } from "react-redux";

import { dataTable5, statusFromType } from "variables/general.jsx";
import { changeUrl } from "variables/coreWeb.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ServiceOrderTable from "./ServiceOrderTable";
import ChartistGraph from "react-chartist";
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart
} from "variables/charts.jsx";
import CoreTitleCard from "./CoreTitleCard";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...userProfileStyles
};

class ServiceOrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <h4>Points Overview</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CoreTitleCard
              color="rose"
              title={"Total Points"}
              subtitle={"Your Total RankFans Points"}
              value={10}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CoreTitleCard
              color="warning"
              title={"Monthly Average"}
              subtitle={"Average Points Per Month"}
              value={10}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CoreTitleCard
              color="info"
              title={"Last Points"}
              subtitle={"How much your last redemtion was"}
              value={10}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {this.props.userId != undefined ? (
            <CoreApiReq
              endpointToCall={"Read_All_Org_Service_Orders_By_User_ID"}
              afterActionHandler={actions.cw.GOT_ALLORGSERVICEORDERS}
              data={{
                userId: this.props.userId
              }}
            />
          ) : (
            ""
          )}
          <GridItem xs={12}>
            <h4>Points list <small> - Points you have redeemed from brands</small></h4>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ServiceOrderTable
                  serviceOrderList={this.props.orgServiceOrderList}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.pwReducer.selfProfile.userId,
    orgServiceOrderList: state.pwReducer.orgServiceOrderList || []
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServiceOrderList)
);
