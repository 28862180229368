import React from "react";

import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AnimalSpeciesSelect from "components/ProjectWeb/AnimalSpeciesSelect.jsx";
import PhoneNumSelect from "components/CoreWeb/PhoneNumSelect.jsx";
import ProductSelect from "components/CoreWeb/ProductSelect.jsx";
import SocialSelect from "components/CoreWeb/SocialSelect.jsx";
import CoreQuantity from "components/CoreWeb/CoreQuantity.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";
import InvoiceDetails from "components/CoreWeb/InvoiceDetails";
import Check from "@material-ui/icons/Check";
import EventBusy from "@material-ui/icons/EventBusy";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";

import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { dataTable5 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import ServiceSelect from "components/CoreWeb/ServiceSelect";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { orderStatusFromType } from "variables/coreWeb.jsx";
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...userProfileStyles
};

const productSelect = function()
{
  return (<ProductSelect></ProductSelect>);
}

const serviceSelect = function()
{
  return (<ServiceSelect></ServiceSelect>);
}

const quantitySelect = function()
{
  return (<CoreQuantity></CoreQuantity>);
}

class CreateInvoiceStep3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    const fillButtons = [
      { color: "danger", icon: Close },
    ].map((prop, key) => {
      return (
        <Button color={prop.color} className={classes.actionButton} key={key}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
    return (
      <div>
        
   
 
        
        <GridContainer justify="center">
        <GridItem xs={6} sm={6} md={12} lg={12}>
          <ReactTable
                data={dataTable5.dataRows.map((prop, key) => {
                  return {
                    id: key,
                    name: prop[0],
                    position: labelFromType(prop[1]),
                    status: orderStatusFromType(prop[2]),
                    age: prop[3],
                    actions: fillButtons,
                    workingFor: prop[2] != 1 ? '????' : "Coca Cola",
                    totalValue: "100$",
                    productOptions: serviceSelect(),
                    quantitySelect: quantitySelect()
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Service",
                    accessor: "productOptions",
                    sortable: false,
                    filterable: false
                  },
                  
                  {
                    Header: "Quantity",
                    accessor: "quantitySelect",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: "Individual Price",
                    accessor: "totalValue",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: "Multiplied Price",
                    accessor: "totalValue",
                    sortable: false,
                    filterable: false
                  },                
                  
                  
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={3}
                showPaginationTop={false}

                showPaginationBottom={false}
                className="-striped -highlight"
              />
        </GridItem>
        
            
        </GridContainer>
        <br></br>
        <br></br>
        <InvoiceDetails></InvoiceDetails>
        
      </div>
    );
  }
}

export default withStyles(style)(CreateInvoiceStep3);
