import React from "react";

// @material-ui/icons
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import SelectAgreeDisagree from "components/CoreWeb/SelectAgreeDisagree";
import * as actions from "actions/projectActionTypes";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import { labelFromType, campaignTypes } from "variables/project.jsx";
import {
  changeUrl,
  viewButton,
  forwardButton,
  safeFindMetaDataString,
  orderStatusFromType,
} from "variables/coreWeb.jsx";
import { dataTable5 } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { paths } from "variables/projPaths.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import Visibility from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  ...userProfileStyles,
};

const ServiceOrderTable = function(props) {
  const { classes } = props;
  const history = useHistory();
  const generateFillButtons = function(orgId, rowId) {
    return [{ color: "info", icon: Visibility }].map((prop, key) => {
      return (
        <Button
          color={prop.color}
          className={classes.actionButton}
          key={key}
          onClick={function() {
            changeUrl("/campaign/viewApplication/" + orgId + "/" + rowId);
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const findConfigMatchName = function(
    selfServiceFulfilmentConfigCompleteList,
    serviceFulfilmentConfigId
  ) {
    var resp = "";
    if (selfServiceFulfilmentConfigCompleteList === undefined) {
      return resp;
    }
    var found = selfServiceFulfilmentConfigCompleteList.find(function(element) {
      return element.serviceFulfilmentConfigId == serviceFulfilmentConfigId;
    });
    if (found) {
      resp = found.name || found.serviceFulfilmentConfigName;
    }
    return resp;
  };

  return (
    <div>
      <ReactTable
        data={props.serviceOrderList.map((prop, key) => {
          return {
            id: key,
            quoteType: labelFromType(prop.serviceId),
            status: orderStatusFromType(prop.orderState),
            actions: viewButton(function() {
              history.push(
                "/loyalty/view" +
                  "/" +
                  prop.orgId +
                  "/" +
                  prop.userServiceFulfilmentConfigId +
                  "/" +
                  prop.serviceOrderId
              );
            }, classes),
            rfpName: prop.serviceFulfilmentConfigName,
          };
        })}
        filterable
        columns={[
          {
            Header: "Points Type",
            accessor: "rfpName",
          },
          {
            Header: "Points Amount",
            accessor: "quoteType",
          },

          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
        defaultPageSize={10}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServiceOrderTable)
);
