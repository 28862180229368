import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "./CreateServiceStep1.jsx";
import CreateServiceStep2 from "./CreateServiceStep2.jsx";
import CreateServiceStep4 from "./CreateServiceStep4.jsx";
import ComingSoonStep from "./ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import PrePaymentRequiredSelect from "components/CoreWeb/PrePaymentRequiredSelect";
import final from "@material-ui/icons/VerifiedUser";
import MandatoryStaffSelect from "components/CoreWeb/MandatoryStaffSelect";
import OptionalStaffSelect from "components/CoreWeb/OptionalStaffSelect";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PricingName from "./PricingName";
import SelectIsActive from "./SelectIsActive";
import {
  createServiceFulfilmentConfigComplete,
  updateServiceFulfilmentConfigComplete
} from "variables/coreRequests.jsx";
import * as raisers from "actions/projectRaisers";
import { rows } from "variables/coreWeb.jsx";
import ServiceFulfilmentConfigResourceMappingView from "components/CoreWeb/ServiceFulfilmentConfigResourceMappingView";
import MandatoryResourcesSelect from "components/CoreWeb/MandatoryResourcesSelect";
import OptionalResourcesSelect from "components/CoreWeb/OptionalResourcesSelect";
import ReactTable from "react-table";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import { getActiveOrg } from "variables/coreWeb.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class PricingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullFilmentConfigs: []
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
      {this.props.match.params.serviceFulfilmentConfigId ? (
        <CoreApiReq
          endpointToCall={"Read_Org_ServiceFulfilmentConfig_Complete"}
          afterActionHandler={actions.cw.GOT_SERVICEFULFILMENT_COMPLETE}
          data={{
            orgId: getActiveOrg(),
            serviceFulfilmentConfigId: this.props.match.params
              .serviceFulfilmentConfigId
          }}
        />
      ) : (
        ""
      )}
        <h4>Loyalty Settings</h4>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardBody>
                <h4>Loyalty Settings Name</h4>
                <PricingName
                  fieldIdentifier={"serviceFulfilmentConfig.name"}
                />
              </CardBody>
            </Card>
            {!this.props.isCreate ? (
              <Card>
                <CardBody>
                  <h4>Loyalty Points Active or Inactive</h4>
                  <SelectIsActive
                    fieldIdentifier={"serviceFulfilmentConfig.isActive"}
                  />
                </CardBody>
              </Card>
            ) : (
              ""
            )}
            {/*!this.props.isCreate ? (
              <Card>
                <CardBody>
                  <h4>
                    Prepayment Required -{" "}
                    <small>
                      In online booking, whether someone must pre-pay
                    </small>
                  </h4>
                  <PrePaymentRequiredSelect
                    disabled={!this.props.isCreate}
                    fieldIdentifier={
                      "serviceFulfilmentConfig.prePaymentRequired"
                    }
                  />
                </CardBody>
              </Card>
            ) : (
              ""
            )*/}
            {/*<ServiceFulfilmentConfigResourceMappingView
              disabled={!this.props.isCreate}
            />*/}
              <Card>
                <CardBody>
                  <h4>Amount Of Loyalty Points Per Redeem</h4>
                  
                  <ReduxCustomInput
                  mainTextLabel={"Enter the number of loyalty points your fans will get"}
                  fieldIdentifier={"serviceFulfilmentConfig.loyaltyToRedeem"}
                  ></ReduxCustomInput>
                </CardBody>
              </Card>
            <h4>Actions</h4>
            <GridContainer justify="center">
              <GridItem>
                {/*!this.props.isCreate ? (
                  <Button color="danger">Delete Pricing</Button>
                ) : (
                  ""
                )*/}
                {!this.props.isCreate ? (
                  <Button onClick={() => {updateServiceFulfilmentConfigComplete(this.props.callApi, {...this.props.pwReducer, metaDataList:this.props.updateMetaData, 'serviceId':this.props.serviceId})}} color="rose">Update Pricing</Button>
                ) : (
                  ""
                )}
                {this.props.isCreate ? (
                  <Button
                    color="rose"
                    onClick={() => {
                      createServiceFulfilmentConfigComplete(this.props.callApi, {...this.props.pwReducer, metadataOptionList:this.props.currentMetaData, 'serviceId':this.props.serviceId});
                    }}
                  >
                    Create Loyalty
                  </Button>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    serviceId: parseInt(ownProps.match.params.serviceId),
    pwReducer: state.pwReducer,
    serviceFulfilmentConfigId: ownProps.match.params.serviceFulfilmentConfigId,
    isCreate: ownProps.match.params.serviceFulfilmentConfigId === undefined,
    currentMetaData: [
      {
        'metaDataIdentifier':'loyaltyToRedeem', 
        'metaData': state.pwReducer.serviceFulfilmentConfig['loyaltyToRedeem'],
        'metaDataGroupIdentifier':'',
        'type':2,
        
      },
    ],
    updateMetaData: [
      {
        'metaDataIdentifier':'loyaltyToRedeem', 
        'metaData': state.pwReducer.serviceFulfilmentConfig['loyaltyToRedeem'],
        'metaDataGroupIdentifier':'',
        'type':2,
        metaDataId:state.pwReducer.serviceFulfilmentConfig.metaDataList ? state.pwReducer.serviceFulfilmentConfig.metaDataList.find(x => {return x.metaDataIdentifier === 'loyaltyToRedeem'}).metaDataId : ''
      },
    ],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    raiseAction: payload => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(PricingView));
