import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Category from "@material-ui/icons/Category";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";

import { hardCodedOrgId } from "variables/project.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import * as makeApiCall from 'actions/makeApiCall';
import { paths } from "variables/projPaths.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import {bindActionCreators} from 'redux';
import { connect } from "react-redux";
import { deleteResourceWithDispatch } from "../../variables/thunks";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle


  
};

class OrgResourceList extends React.Component {
  render() {
    const { classes } = this.props;

    const fillButtons = function(theProps, resourceId) {
      return (
        <div>
          <Button
            className={classes.actionButton}
            color="info"
            size="sm"
            href={ paths.resourceView + "/" + resourceId}
          >
            <Visibility className={classes.icon} />
          </Button>
          <Button
            className={classes.actionButton}
            color="danger"
            size="sm"
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedResourceId",
                value: resourceId
              });
            }}
          >
            <Clear className={classes.icon} />
          </Button>
        </div>
      );
    };

    return (
      <GridContainer justify="center">
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Resources"}
          afterActionHandler={actions.cw.GOT_ALL_ORG_RESOURCES_BY_ORG_ID}
          data={{
            orgId: hardCodedOrgId
          }}
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Category />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Resource List
                <small> - manage your resources</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.props.resourceCompleteList.map((prop, key) => {
                  return {
                    id: key,
                    name: prop.resourceName,
                    actions: fillButtons(this.props, prop.resourceId)
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Resource Name",
                    accessor: "name"
                  },

                  {
                    Header: "Resource Type",
                    accessor: "status"
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {this.props.selectedResourceId ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-200px" }}
              title="Are you sure?"
              onConfirm={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedResourceId",
                  value: undefined
                }) ||
                this.props.dispatch(deleteResourceWithDispatch(
                  this.props.callApi,
                  this.props.selectedResourceId
                ))
              }}
              onCancel={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedResourceId",
                  value: undefined
                });
              }}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            >
              Deleting a resource is permanent
            </SweetAlert>
          ) : (
            ""
          )}
      </GridContainer>
    );
  }
}
function mapStateToProps(state) {
  return {
    resourceCompleteList: state.pwReducer.resourceCompleteList || [],
    selectedResourceId: state.pwReducer.selectedResourceId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: payload => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgResourceList));
