import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from 'react-star-rating-component';
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";

import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ReduxCoreSelect from "./ReduxCoreSelect";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...userProfileStyles
};

class InvoiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { classes } = this.props;
    

    return (
      <div>
      <GridContainer >
          <GridItem xs={0} sm={0} md={4} lg={4}></GridItem>
          <GridItem xs={6} sm={6} md={4} lg={4}><h4>Number Of Items</h4></GridItem>
          <GridItem xs={6} sm={6} md={4} lg={4}>
            <h4>7</h4>
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem xs={0} sm={0} md={4} lg={4}></GridItem>
          <GridItem xs={6} sm={6} md={4} lg={4}><h4>Invoice Total</h4></GridItem>
          <GridItem xs={6} sm={6} md={4} lg={4}>
            <h4>300$</h4>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(userProfileStyles)(InvoiceDetails);











