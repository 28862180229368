import React from "react";
import history from "../history";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import Forward from "@material-ui/icons/Forward";
import StarRatingComponent from "react-star-rating-component";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import { UPLOADSPATH, DEBUG } from "variables/project.jsx";
import { paths } from "variables/projPaths.jsx";
import { APIURL } from "variables/project.jsx";
import { getActiveOrg, resetActiveOrg } from "variables/coreWeb.jsx";

const set = function(obj, path, value) {
  //console.log("---OBJ--- " + JSON.stringify(obj));
  //console.log("---Path---" + JSON.stringify(path));
  //console.log("---Value---" + JSON.stringify(value));
  if (obj && path) {
    var schema = obj; // a moving reference to internal objects within obj
    var pList = path.split(".");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    schema[pList[len - 1]] = value;
  }
};
const getObjProp = function(obj, path) {
  //console.log("Getting " + path + " from : " + JSON.stringify(obj));
  if (obj && path) {
    var schema = obj; // a moving reference to internal objects within obj
    var pList = path.split(".");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    var ret = schema[pList[len - 1]];
    //console.log("Found: " + JSON.stringify(ret))
    return ret;
  }
};

const stringMatch = function(item, valueToMatchAgainst) {
  return item === valueToMatchAgainst;
};

const handleChange = (name) => (event) => {
  alert(name + " " + event.target.value);
  /*this.setState({
    [name]: event.target.value,
  });*/
};

Array.prototype.remove = function(value) {
  return this.filter((f) => f != value);
};

const toggleGroupButtonSelect = function(name, propValue) {
  //alert("toggle");
  console.log(propValue);
  console.log(propValue.fillMe);
  //alert(console.log(name) + " " + console.log(propValue.value));
  if (!propValue.fillMe || !propValue.fillMe.includes(propValue.value)) {
    //propValue.fillMe.push(propValue.value);
    propValue.actionAdd(propValue);
  } else {
    //propValue.fillMe = propValue.fillMe.remove(propValue.value);
    propValue.actionRm(propValue);
  }
  //alert(propValue.fillMe.length);
};

const genericAddList = function(propertyListToCheck, valueToAdd) {
  var currentList = !propertyListToCheck ? [] : propertyListToCheck.slice();
  if (!currentList.includes(valueToAdd)) {
    currentList.push(valueToAdd);
    return currentList;
  }
  return currentList;
};

const genericRmList = function(propertyListToCheck, valueToRemove) {
  var currentList = !propertyListToCheck ? [] : propertyListToCheck.slice();
  if (currentList.includes(valueToRemove)) {
    currentList = currentList.remove(valueToRemove);
  }
  return currentList;
};

const genericHandleListItem = function(propertyListToCheck, valueToAddRemove) {
  var currentList = !propertyListToCheck ? [] : propertyListToCheck.slice();
  if (currentList.includes(valueToAddRemove)) {
    currentList = currentList.remove(valueToAddRemove);
  } else {
    currentList.push(valueToAddRemove);
  }
  return currentList;
};

const changeUrl = function(urlToNavTo) {
  history.push(urlToNavTo);
  window.location.reload();
};

const viewButton = function(clickFunc, classes) {
  return [{ color: "info", icon: Visibility }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc();
        }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
};

const forwardButton = function(clickFunc, classes) {
  return [{ color: "success", icon: Forward }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc();
        }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
};

const deleteButton = function(clickFunc, classes) {
  return [{ color: "danger", icon: Close }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc();
        }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
};

const tableButton = function(clickFunc, classes, color, icon, callbackParams) {
  return [{ color: color, icon: icon }].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        onClick={function() {
          clickFunc(callbackParams);
        }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
};

const setMetaDataOntoObject = function(metaDataList, propsToLookFor) {
  var resp = {};
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (propsToLookFor.includes(currentItem.metaDataIdentifier)) {
      resp[currentItem.metaDataIdentifier] = isNaN(currentItem.metaData)
        ? currentItem.metaData
        : parseInt(currentItem.metaData);
    }
  }
  return resp;
};

const getAllMetaDataIntoArray = function(metaDataList, propsToLookFor) {
  var resp = [];
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (propsToLookFor.includes(currentItem.metaDataIdentifier)) {
      resp.push(currentItem.metaData);
    } else {
      return [];
    }
  }
  return resp;
};

const getAllMetaDataIntoObject = function(metaDataList, propsToLookFor) {
  var resp = {};
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (propsToLookFor.includes(currentItem.metaDataIdentifier)) {
      resp[currentItem.metaDataIdentifier] = currentItem.metaData;
    } else {
      return {};
    }
  }
  return resp;
};

const safeFindMetaDataString = function(metaDataList, valueToLookFor) {
  var resp = "";
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (valueToLookFor === currentItem.metaDataIdentifier) {
      resp = currentItem.metaData;
    }
  }
  return resp;
};

const safeFindMetaDataNumbers = function(metaDataList, valueToLookFor) {
  var resp = [];
  for (var i = 0; i < metaDataList.length; i++) {
    var currentItem = metaDataList[i];
    if (valueToLookFor === currentItem.metaDataIdentifier) {
      resp = currentItem.metaData.split(",").map(Number);
    }
  }
  return resp;
};

const enumMedicalTypeToString = function(enumValue) {
  switch (enumValue) {
    case 2:
      return "Note";
    case 3:
      return "Procedure";
    case 4:
      return "Behaviour";
    case 5:
      return "Allergy";
    case 6:
      return "Observation";
    case 7:
      return "Dental";
    case 8:
      return "Laboratory";
    case 9:
      return "Weight Measurement";
    case 10:
      return "Height Measurement";
    case 11:
      return "Medication";
    case 12:
      return "Temperature Measurement";
    case 13:
      return "Pulse Measurement";
    case 14:
      return "Respiration Measurement";
  }
};
const enumAnimalSpeciesToString = function(enumValue) {
  switch (enumValue) {
    case 1:
      return "Avian";
    case 2:
      return "Bovine";
    case 3:
      return "Camelid";
    case 4:
      return "Canine";
    case 5:
      return "Caprine";
    case 6:
      return "Cavies";
    case 7:
      return "Cervidae";
    case 8:
      return "Equine";
    case 9:
      return "Feline";
    case 10:
      return "Lapine";
    case 11:
      return "Maurine";
    case 12:
      return "Ovine";
  }
};

const convertToStars = function(starValue) {
  return (
    <StarRatingComponent value={starValue} color="success" size="sm">
      Active
    </StarRatingComponent>
  );
};

const activeStatusFromType = function(statusValue, callbackFunction) {
  if (statusValue == 1) {
    return (
      <Button
        color=""
        size="sm"
        onClick={() => (callbackFunction ? callbackFunction() : "")}
      >
        Unknown
      </Button>
    );
  }
  if (statusValue == 2) {
    return (
      <Button
        color="success"
        size="sm"
        onClick={() => (callbackFunction ? callbackFunction() : "")}
      >
        Active
      </Button>
    );
  }
  if (statusValue == 3) {
    return (
      <Button
        color="danger"
        size="sm"
        onClick={() => (callbackFunction ? callbackFunction() : "")}
      >
        InActive
      </Button>
    );
  }
};
const orderStatusFromType = function(statusValue) {
  if (statusValue == 2) {
    return (
      <Button color="success" size="sm">
        Pending
      </Button>
    );
  }
  if (statusValue == 3) {
    return (
      <Button color="" size="sm">
        Confirmed
      </Button>
    );
  }
  if (statusValue == 4) {
    return (
      <Button color="danger" size="sm">
        Rejected
      </Button>
    );
  }
  if (statusValue == 5) {
    return (
      <Button color="warning" size="sm">
        InProgress
      </Button>
    );
  }
  if (statusValue == 6) {
    return (
      <Button color="success" size="sm">
        Confirmed
      </Button>
    );
  }
  if (statusValue == 7) {
    return (
      <Button color="" size="sm">
        Invoiced
      </Button>
    );
  }
  if (statusValue == 8) {
    return (
      <Button color="danger" size="sm">
        Paid
      </Button>
    );
  }
  if (statusValue == 9) {
    return (
      <Button color="warning" size="sm">
        Closed
      </Button>
    );
  }
  if (statusValue == 10) {
    return (
      <Button color="warning" size="sm">
        Refunded
      </Button>
    );
  }
  if (statusValue == 11) {
    return (
      <Button color="warning" size="sm">
        Cancelled
      </Button>
    );
  }
  if (statusValue == 12) {
    return (
      <Button color="warning" size="sm">
        Awaiting Payment
      </Button>
    );
  }
};
var yesNoTextDescs = [{ text: "Yes" }, { text: "No" }];
function pluck(array, key) {
  return array.map((o) => o[key]);
}
function rows(objsToLoop, propText, propValue) {
  var resp = [];
  for (var i = 0; i < objsToLoop.length; i++) {
    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    var theValue =
      objsToLoop[i].value ||
      (propValue &&
        (typeof propValue === "function"
          ? propValue(objsToLoop[i])
          : objsToLoop[i][propValue]
            ? objsToLoop[i][propValue]
            : undefined)) ||
      i + 1;
    resp.push(
      <MenuItem value={theValue} key={i}>
        {(propText &&
          (typeof propText === "function"
            ? propText(objsToLoop[i])
            : objsToLoop[i][propText]
              ? objsToLoop[i][propText]
              : undefined)) ||
          objsToLoop[i].text}
      </MenuItem>
    );
  }
  return resp;
}
const REGISTRATION_VENDOR = 4;
const REGISTRATION_CLIENT = 3;

const gridFix = {
  width: "100% !important",
};

const defaultChangeReduxCoreSelect = function(dispatch, ownProps, payload) {
  var newPayload = {};
  newPayload.value = payload;
  newPayload.fieldIdentifier = ownProps.fieldIdentifier;
  if (
    ownProps.updateAction === actions.cw.UPREDUXSELECTINPUT ||
    ownProps.updateAction === undefined
  ) {
    console.log("---" + ownProps.updateAction);
    dispatch(
      raisers.createActionGeneric(newPayload, actions.cw.UPREDUXSELECTINPUT)
    );
  } else {
    dispatch(raisers.createActionGeneric(newPayload, ownProps.updateAction));
  }
};

const excludeSelectedExceptCurrent = function(
  completeList,
  currentlySelectedList,
  fieldIdentifier,
  state
) {
  var resp = completeList.filter(function(value) {
    return (
      !currentlySelectedList.includes(value.resourceId) ||
      state[fieldIdentifier] == value.resourceId
    );
  });
  if (currentlySelectedList.length == completeList.length) {
    resp.unshift({});
  }
  return resp;
};
const generateResourceSfcMapRows = function(
  rowCount,
  fieldIdentifierBase,
  resourceList,
  mandatory,
  currentlySelectedList,
  state,
  textDisplayName,
  valueName,
  isDisabled
) {
  var resp = [];
  for (var i = 0; i < rowCount; i++) {
    var fieldIdentifier = fieldIdentifierBase + "ResourceSelect" + i.toString();
    resp.push({
      name: i.toString(),
      resourceSelect: (
        <ReduxCoreSelect
          options={rows(
            excludeSelectedExceptCurrent(
              resourceList,
              currentlySelectedList,
              fieldIdentifier,
              state
            ),
            textDisplayName,
            valueName
          )}
          fieldIdentifier={fieldIdentifier}
          disabled={isDisabled}
        />
      ),
      durationSelect: (
        <ReduxCustomInput
          fieldIdentifier={fieldIdentifierBase + "Duration" + i.toString()}
          disabled={isDisabled}
        />
      ),
      salesPrice: (
        <ReduxCustomInput
          fieldIdentifier={fieldIdentifierBase + "SalesPrice" + i.toString()}
          disabled={isDisabled}
        />
      ),
      reqType: mandatory ? "Mandatory" : "Optional",
    });
  }
  return resp;
};
const generateFileUrl = function(fileId, fileExtension) {
  var returnMe = UPLOADSPATH.concat(fileId.toString(), fileExtension);
  return returnMe;
};
const getMostRecentFileFromList = function(fileList) {
  var activeFile = null;
  if (fileList && fileList.length > 0) {
    activeFile = fileList.sort((a, b) => a.fileId - b.fileId)[
      fileList.length - 1
    ];
  }
  return activeFile;
};
const nullRespVals = function(objToNull) {
  var activeFile = objToNull;
  delete activeFile.func_status;
  delete activeFile.func_msg;
  delete activeFile.df;
  delete activeFile.dl;
  return activeFile;
};

const coreWebStyles = {
  cardHeaderCenter: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "5em",
  },
};
const next = function(prop) {
  return function(arr) {
    var used = arr.reduce(function(o, v) {
      o[v[prop]] = true;
      return o;
    }, {});
    for (var i = 1; used[i]; i++);
    return i;
  };
};
const orderStatus = [
  { text: "Pending", value: 2 },
  { text: "Rejected", value: 4 },
  { text: "Requested Contact", value: 12 },
];
var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const uploadFile = function(files, dataParamKeyValuePairs, endpointToSendTo) {
  var formData = new FormData();

  files.map((file) => {
    formData.append(`Files`, file);
    console.log(file);
  });

  dataParamKeyValuePairs.map((option) => {
    formData.append("Params", option);
  });

  fetch(APIURL + endpointToSendTo, {
    // content-type header should not be specified!
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("access_token"),
    },
  })
    .then((response) => {
      response.json();
    })
    .then(() => {})
    .catch((error) => console.log(error));
};

const updateProduct = function(callApi, theProps) {
  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Product",
    theProps,
    actions.cw.API_PRODUCT_UPDATE
  );
};

const updateResource = function(callApi, theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.allowsOverlaps = 2;
  payLoad.creatorId = 3;
  payLoad.depthUnitType = 2;
  payLoad.depthValue = 0;
  payLoad.heightUnitType = 2;
  payLoad.heightValue = 0;
  payLoad.latitude = 51.50722;
  payLoad.longitude = -0.1275;
  payLoad.maxAppointmentDuration = 7200000;
  payLoad.maxAppointmentFutureTimeInMs = 0;
  payLoad.maxDailyUserSlots = 2;
  payLoad.maxExceptionDuration = 604800000;
  payLoad.orgId = getActiveOrg();
  payLoad.resourceId = theProps.resourceId;
  payLoad.resourceName = theProps.resourceName;
  payLoad.slotDuration = 10800000;
  payLoad.timeAllocationType = 2;
  payLoad.timeZoneIANA = "";
  payLoad.userId = 0;
  payLoad.widthUnitType = 2;
  payLoad.widthValue = 0;

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Resource",
    payLoad,
    actions.cw.API_RESOURCE_UPDATE
  );
};

const deletePatient = function(callMe, animalId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.animalId = animalId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Animal_By_Animal_ID",
    payLoad,
    actions.cw.API_ANIMAL_DELETE
  );
};

const updateAnimal = function(callApi, theProps) {
  const {
    animalBreed,
    animalId,
    animalSpecies,
    bloodGroup,
    dateOfBirth,
    deceasedDate,
    deceasedStatus,
    desexedStatus,
    gender,
    insuranceReferenceNumber,
    isActive,
    mainColour,
    microchipId,
    name,
    orgId,
    passportNumber,
    secondaryColour,
  } = theProps;
  const json = {
    animalBreed,
    animalId,
    animalSpecies,
    bloodGroup,
    dateOfBirth,
    deceasedDate,
    deceasedStatus,
    desexedStatus,
    gender,
    insuranceReferenceNumber,
    isActive,
    mainColour,
    microchipId,
    name,
    orgId,
    passportNumber,
    secondaryColour,
  };

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Org_Animal",
    json,
    actions.cw.API_ANIMAL_UPDATE
  );
};

const deleteContact = function(callMe, contactId) {
  var payLoad = {};
  payLoad.contactId = contactId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Contact",
    payLoad,
    actions.cw.API_CONTACT_DELETE
  );
};

const deleteAddress = function(callMe, addressId) {
  var payLoad = {};
  payLoad.addressId = addressId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Address",
    payLoad,
    actions.cw.API_CONTACT_ADDRESS_DELETE
  );
};

const deleteService = function(callMe, serviceId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.serviceId = serviceId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Service",
    payLoad,
    actions.cw.API_SERVICE_DELETE
  );
};

const deleteProduct = function(callMe, productId) {
  console.log(productId);
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.productId = productId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Product",
    payLoad,
    actions.cw.API_PRODUCT_DELETE
  );
};

const deleteResource = function(callMe, resourceId) {
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.resourceId = resourceId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Resource",
    payLoad,
    actions.cw.API_RESOURCE_DELETE
  );
};

const deleteMedical = function(callMe, medicalId) {
  var payLoad = {};
  payLoad.medicalNoteId = medicalId;
  payLoad.orgId = getActiveOrg();

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_MedicalNote",
    payLoad,
    actions.cw.API_MEDICAL_DELETE
  );
};

const createServiceFulfilmentConfigComplete = function(callApi, theProps) {
  var payLoad = {};
  payLoad.functionName = "CreateServiceFulfilmentConfigComplete";
  var json = {};
  json.orgId = getActiveOrg();
  json.isActive = 2;
  json.name = theProps.name || theProps.serviceFulfilmentConfig.name;
  json.serviceId = theProps.serviceId;
  json.numberOfRequiredResources = 0;
  json.prePaymentRequired = 2;
  json.advancedOrBasicServiceFulfilmentConfig = 3;
  json.isQueueActive = 2;
  json.serviceFulfilmentConfigId = 0;
  json.maxOrdersPerDay = 0;
  json.maxDailyLimit = 0;
  json.serviceFulfilmentConfigResourceOptionMapList = [];
  json.metadataOptionList = theProps.metadataOptionList || [];
  json.contactId = 0;
  if (theProps.mandatoryResources > 0) {
    for (var i = 0; i < theProps.mandatoryResources; i++) {
      var addMe = {};
      addMe.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMe.resourceId =
        theProps["mandatoryResConfigResourceSelect" + i.toString()];
      addMe.durationMilliseconds =
        theProps["mandatoryResConfigDuration" + i.toString()];
      addMe.monetaryAmount =
        theProps["mandatoryResConfigSalesPrice" + i.toString()];
      addMe.monetaryCurrency = 840;
      addMe.serviceId = theProps.serviceId;
      addMe.serviceFulfilmentResourceConfigRelationship = 3;
      addMe.serviceFulfilmentConfigId = 0;
      addMe.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMe);
    }
  }
  if (theProps.optionalResources > 0) {
    for (var l = 0; l < theProps.optionalResources; l++) {
      var addMeOptional = {};
      addMeOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeOptional.resourceId =
        theProps["optionalResConfigResourceSelect" + l.toString()];
      addMeOptional.durationMilliseconds =
        theProps["optionalResConfigDuration" + l.toString()];
      addMeOptional.monetaryAmount =
        theProps["optionalResConfigSalesPrice" + l.toString()];
      addMeOptional.monetaryCurrency = 840;
      addMeOptional.serviceId = theProps.serviceId;
      addMeOptional.serviceFulfilmentResourceConfigRelationship = 3;
      addMeOptional.serviceFulfilmentConfigId = 0;
      addMeOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeOptional);
    }
  }
  if (theProps.mandatoryStaff > 0) {
    for (var k = 0; k < theProps.mandatoryStaff; k++) {
      var addMeStaff = {};
      addMeStaff.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMeStaff.resourceId =
        theProps["mandatoryStaffConfigResourceSelect" + k.toString()];
      addMeStaff.durationMilliseconds =
        theProps["mandatoryStaffConfigDuration" + k.toString()];
      addMeStaff.monetaryAmount =
        theProps["mandatoryStaffConfigSalesPrice" + k.toString()];
      addMeStaff.monetaryCurrency = 840;
      addMeStaff.serviceId = theProps.serviceId;
      addMeStaff.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaff.serviceFulfilmentConfigId = 0;
      addMeStaff.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeStaff);
    }
  }
  if (theProps.optionalStaffs > 0) {
    for (var j = 0; j < theProps.optionalStaffs; j++) {
      var addMeStaffOptional = {};
      addMeStaffOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeStaffOptional.resourceId =
        theProps["optionalStaffConfigResourceSelect" + j.toString()];
      addMeStaffOptional.durationMilliseconds =
        theProps["optionalStaffConfigDuration" + j.toString()];
      addMeStaffOptional.monetaryAmount =
        theProps["optionalStaffConfigSalesPrice" + j.toString()];
      addMeStaffOptional.monetaryCurrency = 840;
      addMeStaffOptional.serviceId = theProps.serviceId;
      addMeStaffOptional.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaffOptional.serviceFulfilmentConfigId = 0;
      addMeStaffOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(
        addMeStaffOptional
      );
    }
  }

  payLoad.json = JSON.stringify(json);
  callApi.req(
    localStorage.getItem("access_token"),
    "CreateFunction",
    payLoad,
    actions.cw.API_RESP_UPDATE
  );
};

const updateServiceFulfilmentConfigComplete = function(callApi, theProps) {
  var payLoad = {};
  var json = {};
  json.orgId = theProps.serviceFulfilmentConfig.orgId;
  json.isActive = theProps.serviceFulfilmentConfig.isActive;
  json.name = theProps.name || theProps.serviceFulfilmentConfig.name;
  json.serviceId = theProps.serviceId;
  json.numberOfRequiredResources = 0;
  json.prePaymentRequired = 2;
  json.advancedOrBasicServiceFulfilmentConfig = 3;
  json.isQueueActive = 2;
  json.serviceFulfilmentConfigId =
    theProps.serviceFulfilmentConfig.serviceFulfilmentConfigId;
  json.maxOrdersPerDay = 0;
  json.maxDailyLimit = 0;
  json.serviceFulfilmentConfigResourceOptionMapList = [];
  json.metaDataList = theProps.metaDataList || [];
  json.contactId = 0;
  if (theProps.mandatoryResources > 0) {
    for (var i = 0; i < theProps.mandatoryResources; i++) {
      var addMe = {};
      addMe.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMe.resourceId =
        theProps["mandatoryResConfigResourceSelect" + i.toString()];
      addMe.durationMilliseconds =
        theProps["mandatoryResConfigDuration" + i.toString()];
      addMe.monetaryAmount =
        theProps["mandatoryResConfigSalesPrice" + i.toString()];
      addMe.monetaryCurrency = 840;
      addMe.serviceId = theProps.serviceId;
      addMe.serviceFulfilmentResourceConfigRelationship = 3;
      addMe.serviceFulfilmentConfigId = 0;
      addMe.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMe);
    }
  }
  if (theProps.optionalResources > 0) {
    for (var l = 0; l < theProps.optionalResources; l++) {
      var addMeOptional = {};
      addMeOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeOptional.resourceId =
        theProps["optionalResConfigResourceSelect" + l.toString()];
      addMeOptional.durationMilliseconds =
        theProps["optionalResConfigDuration" + l.toString()];
      addMeOptional.monetaryAmount =
        theProps["optionalResConfigSalesPrice" + l.toString()];
      addMeOptional.monetaryCurrency = 840;
      addMeOptional.serviceId = theProps.serviceId;
      addMeOptional.serviceFulfilmentResourceConfigRelationship = 3;
      addMeOptional.serviceFulfilmentConfigId = 0;
      addMeOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeOptional);
    }
  }
  if (theProps.mandatoryStaff > 0) {
    for (var k = 0; k < theProps.mandatoryStaff; k++) {
      var addMeStaff = {};
      addMeStaff.serviceFulfilmentResourceConfigRequiredOptional = 3;
      addMeStaff.resourceId =
        theProps["mandatoryStaffConfigResourceSelect" + k.toString()];
      addMeStaff.durationMilliseconds =
        theProps["mandatoryStaffConfigDuration" + k.toString()];
      addMeStaff.monetaryAmount =
        theProps["mandatoryStaffConfigSalesPrice" + k.toString()];
      addMeStaff.monetaryCurrency = 840;
      addMeStaff.serviceId = theProps.serviceId;
      addMeStaff.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaff.serviceFulfilmentConfigId = 0;
      addMeStaff.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(addMeStaff);
    }
  }
  if (theProps.optionalStaffs > 0) {
    for (var j = 0; j < theProps.optionalStaffs; j++) {
      var addMeStaffOptional = {};
      addMeStaffOptional.serviceFulfilmentResourceConfigRequiredOptional = 2;
      addMeStaffOptional.resourceId =
        theProps["optionalStaffConfigResourceSelect" + j.toString()];
      addMeStaffOptional.durationMilliseconds =
        theProps["optionalStaffConfigDuration" + j.toString()];
      addMeStaffOptional.monetaryAmount =
        theProps["optionalStaffConfigSalesPrice" + j.toString()];
      addMeStaffOptional.monetaryCurrency = 840;
      addMeStaffOptional.serviceId = theProps.serviceId;
      addMeStaffOptional.serviceFulfilmentResourceConfigRelationship = 2;
      addMeStaffOptional.serviceFulfilmentConfigId = 0;
      addMeStaffOptional.orgId = theProps.orgId;
      json.serviceFulfilmentConfigResourceOptionMapList.push(
        addMeStaffOptional
      );
    }
  }

  var stringPayLoad = {
    functionName: "Update_Org_ServiceFulfilmentConfig",
    json: JSON.stringify(json),
  };
  callApi.req(
    localStorage.getItem("access_token"),
    "UpdateFunction",
    stringPayLoad,
    actions.cw.API_RESP_UPDATE
  );
};

const updateContact = function(callApi, theProps) {
  const {
    contactAcquisition,
    contactFirstName,
    contactId,
    contactLastName,
    contactTitle,
    contactType,
    emailAddress,
    orgId,
    orgName,
    userId,
  } = theProps;
  const json = {
    contactAcquisition,
    contactFirstName,
    contactId,
    contactLastName,
    contactTitle,
    contactType,
    emailAddress,
    orgId,
    orgName,
    userId,
  };

  callApi.req(
    localStorage.getItem("access_token"),
    "Update_Contact",
    json,
    actions.cw.API_CUSTOMER_UPDATE
  );
};

const updateService = function(callApi, theProps) {
  var payLoad = {
    functionName: "Update_Service",
    json: JSON.stringify(theProps),
  };
  callApi.req(
    localStorage.getItem("access_token"),
    "UpdateFunction",
    payLoad,
    actions.cw.API_SERVICE_UPDATE
  );
};

export {
  // data for React Big Calendar in Calendar view
  resetActiveOrg,
  set,
  getObjProp,
  stringMatch,
  handleChange,
  toggleGroupButtonSelect,
  genericAddList,
  genericRmList,
  genericHandleListItem,
  changeUrl,
  viewButton,
  deleteButton,
  forwardButton,
  tableButton,
  setMetaDataOntoObject,
  getAllMetaDataIntoArray,
  getAllMetaDataIntoObject,
  safeFindMetaDataString,
  safeFindMetaDataNumbers,
  enumAnimalSpeciesToString,
  convertToStars,
  activeStatusFromType,
  orderStatusFromType,
  pluck,
  yesNoTextDescs,
  rows,
  REGISTRATION_VENDOR,
  REGISTRATION_CLIENT,
  gridFix,
  defaultChangeReduxCoreSelect,
  generateResourceSfcMapRows,
  generateFileUrl,
  getMostRecentFileFromList,
  enumMedicalTypeToString,
  nullRespVals,
  coreWebStyles,
  next,
  orderStatus,
  groupBy,
  uploadFile,
  updateProduct,
  updateResource,
  deletePatient,
  updateAnimal,
  deleteContact,
  deleteAddress,
  createServiceFulfilmentConfigComplete,
  updateServiceFulfilmentConfigComplete,
  deleteService,
  deleteProduct,
  deleteResource,
  deleteMedical,
  updateContact,
  updateService,
};
