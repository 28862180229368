import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// Snackbar alert
import Snackbar from "components/Snackbar/Snackbar";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import LocationOn from "@material-ui/icons/LocationOn";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import OrganisationType from "components/CoreWeb/OrganisationType";
import EmployeeCount from "components/CoreWeb/EmployeeCount";
import NumberRangeSelect from "components/CoreWeb/NumberRangeSelect";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";
import MonetaryCurrency from "components/CoreWeb/MonetaryCurrency";
import * as makeApiCall from "actions/makeApiCall";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import * as actions from "actions/projectActionTypes";

import avatar from "assets/img/companylogo.png";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useHistory } from "react-router-dom";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      <Marker position={{ lat: props.latitude, lng: props.longitude }} />
    </GoogleMap>
  ))
);

const style = {};

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTheViewProps = function(theProps) {
    var json = {};
    json.orgName = theProps.orgDetails.orgName;
    json.creatorId = theProps.creatorId;
    json.monetaryCurrency = theProps.monetaryCurrency;
    json.businessType = 2;
    json.isClaimed = 3;
    json.metaDataOptionList = [];
    json.metaDataOptionList.push({
      metadataidentifier: "employeeCount",
      metaData: theProps.orgDetails.employeeCount,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "organisationType",
      metaData: theProps.orgDetails.organisationType,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "yearEstablished",
      metaData: theProps.orgDetails.yearEstablished,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "orgNumber",
      metaData: theProps.orgDetails.orgNumber,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "yearsTrading",
      metaData: theProps.orgDetails.yearsTrading,
      type: 2,
    });
    json.metaDataOptionList.push({
      metadataidentifier: "companyProfileVision",
      metaData: theProps.orgDetails.companyProfileVision,
      type: 2,
    });
    return json;
  };

  createOrganization = function(callMe, theProps) {
    var payLoad = {};
    payLoad.json = JSON.stringify(this.getTheViewProps(theProps));
    payLoad.functionName = "CreateOrganisation";

    const createOrgRecord = async () => {
      callMe.req(
        localStorage.getItem("access_token"),
        "CreateFunction",
        payLoad,
        actions.cw.API_RESP_CREATE
      );
    };

    createOrgRecord().then((response) => {
      if (response.ok) {
        // redirect to dashboard
        this.setState({ isRecordCreated: true });
      }
    });
  };

  updateOrganization = function(callMe, theProps) {
    var payLoad = {};
    var json = this.getTheViewProps(theProps);
    json.orgId = theProps.orgDetails.orgId;
    json.applicationGUID = theProps.orgDetails.applicationGUID;
    json.latitude = theProps.orgDetails.latitude;
    json.longitude = theProps.orgDetails.longitude;
    json.subscriptionDefinitionId =
      theProps.orgDetails.subscriptionDefinitionId;
    payLoad.json = JSON.stringify(json);
    payLoad.functionName = "UpdateOrganisation";

    const updateOrgRecord = () => {
      callMe.req(
        localStorage.getItem("access_token"),
        "UpdateFunction",
        payLoad,
        actions.cw.API_RESP_UPDATE
      );
    };

    updateOrgRecord().then((response) => {
      if (response.ok) {
        this.setState({ isRecordUpdated: true });
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.props.match.params.id && (
          <CoreApiReq
            endpointToCall={"ReadOrganisationComplete"}
            afterActionHandler={actions.cw.GOT_ORGDETAILSLIMITED}
            data={{
              orgId: this.props.match.params.id,
            }}
          />
        )}

        {/**  Success alert message **/}
        <Snackbar
          open={this.state.isRecordCreated}
          place="tr"
          close={true}
          closeNotification={() => this.setState({ isRecordCreated: false })}
          message="Organization created successfully"
          color="success"
        />

        <Snackbar
          open={this.state.isRecordUpdated}
          place="tr"
          close={true}
          closeNotification={() => this.setState({ isRecordUpdated: false })}
          message="Organization updated successfully"
          color="success"
        />

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <NavPills
              color="rose"
              alignCenter
              tabs={[
                {
                  tabButton: "Settings",
                  tabIcon: LocationOn,
                  tabContent: (
                    <div>
                      {/*<h4>Company Image</h4>
                      <Card>
                        <CardBody>
                          <GridContainer justify="center">
                            <GridItem>
                              <ImageUpload />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                      <h4
                        style={{ color: "black" }}
                        className={classes.cardIconTitle}
                      >
                        Organization Details
                        <small> - Present your brand to others</small>
                      </h4>
                      */}
                      <Card>
                        <CardBody>
                          <h4>Organization Name</h4>
                          <ReduxCustomInput
                            subTextLabel="(required)"
                            updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                            fieldIdentifier={"orgDetails.orgName"}
                            textValue={this.props.orgDetails.orgName}
                            multiLine={1}
                          />
                        </CardBody>
                      </Card>
                      {this.props.match.params.id &&
                        ((
                          <Card>
                            <CardBody>
                              <h4>Organization Id/Number</h4>
                              <ReduxCustomInput
                                subTextLabel="(read only)"
                                updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                                fieldIdentifier={"orgDetails.orgId"}
                                multiLine={1}
                                disabled={true}
                              />
                            </CardBody>
                          </Card>
                        ): "")}
                      <Card>
                        <CardBody>
                          <h4>Organization Type</h4>
                          <OrganisationType
                            inputProps={{
                              value: this.props.organisationType,
                            }}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4>Employee Count</h4>
                          <EmployeeCount
                            inputProps={{
                              value: this.props.employeeCount,
                            }}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4>Year Established</h4>
                          <NumberRangeSelect
                            placeholder="Year Established"
                            start={1960}
                            stop={2019}
                            useValueNotIndex={true}
                            fieldIdentifier={"orgDetails.yearEstablished"}
                            inputProps={{
                              value: this.props.yearEstablished,
                            }}
                            descending={true}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4>Years Trading</h4>
                          <NumberRangeSelect
                            placeholder="Years Trading"
                            start={0}
                            stop={15}
                            lastOptionTextEnd={"+"}
                            useValueNotIndex={true}
                            fieldIdentifier={"orgDetails.yearsTrading"}
                            inputProps={{
                              value: this.props.yearsTrading,
                            }}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4>Organisation Currency</h4>
                          <MonetaryCurrency
                            fieldIdentifier={"orgDetails.monetaryCurrency"}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4>Organization Vision/Description</h4>
                          <ReduxCustomInput
                            subTextLabel="(required)"
                            updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                            textValue={this.props.companyProfileVision}
                            multiLine={5}
                            fieldIdentifier={"orgDetails.companyProfileVision"}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  ),
                },
                {
                  tabButton: "Location",
                  tabIcon: LocationOn,
                  tabContent: (
                    <Card profile>
                      <CardHeader>
                        <h4>This Organization Location</h4>
                      </CardHeader>

                      <RegularMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9MFs8Q-HTKk8A5gbQmhWITQ2vxpL1LY4"
                        loadingElement={<div style={{ height: "100%" }} />}
                        containerElement={<div style={{ height: "800px" }} />}
                        mapElement={<div style={{ height: "100%" }} />}
                        latitude={this.props.orgDetails.latitude || 59.334591}
                        longitude={this.props.orgDetails.longitude || 18.06324}
                      />
                    </Card>
                  ),
                },
              ]}
            />
          </GridItem>
          <GridItem>
            {this.props.match.params.id && (
              <Button
                color="info"
                className={classes.updateProfileButton}
                onClick={() => {
                  this.updateOrganization(this.props.callApi, this.props);
                }}
              >
                Update Details
              </Button>
            )}
            {!this.props.match.params.id && (
              <Button
                color="info"
                className={classes.updateProfileButton}
                onClick={() => {
                  this.createOrganization(this.props.callApi, this.props);
                }}
              >
                Create Organization
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CompanyProfile.propTypes = {};

CompanyProfile.defaultProps = {
  orgDetails: {},
};

function mapStateToProps(state) {
  return {
    creatorId: state.pwReducer.selfProfile.userId,
    orgDetails: state.pwReducer.orgDetails,
    companyProfileVision: state.pwReducer.orgDetails.companyProfileVision,
    organisationType: parseInt(state.pwReducer.orgDetails.organisationType),
    employeeCount: parseInt(state.pwReducer.orgDetails.employeeCount),
    yearEstablished: parseInt(state.pwReducer.orgDetails.yearEstablished),
    yearsTrading: parseInt(state.pwReducer.orgDetails.yearsTrading),
    monetaryCurrency: parseInt(state.pwReducer.orgDetails.monetaryCurrency),
    orgNumber: state.pwReducer.orgDetails.orgNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CompanyProfile));
