import React from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import ContactView from "components/CoreWeb/ContactView.jsx";

class CreateCustomer extends React.Component {
  render() {
    return (
      <div>
      
      <ContactView isCreate={true}></ContactView>
      {/* <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={11}>
          <Card>
      <CardBody>
        <h4>Actions</h4>
        <GridContainer justify="center">
          <GridItem>
            <Button color="rose">Create Customer</Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
          </GridItem>
        </GridContainer> */}
      
    </div>
    );
  }
}

export default CreateCustomer;
