import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import ContactViewOptions from "./ContactViewOptions";
import * as actions from "actions/projectActionTypes";
import { hardCodedOrgId } from "variables/project";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import { createCustomer } from "../../variables/coreCreateRequests";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { updateContact } from "../../variables/coreRequests";
import { stat } from "fs";
import { updateCustomerWithDispatch } from "../../variables/thunks";

class ContactView extends React.Component {
  render() {
    return (
      <div>
        {this.props.match && this.props.match.params.contactId != undefined ? (
          <CoreApiReq
            endpointToCall={"ReadContactCompleteByContactID"}
            afterActionHandler={actions.cw.GOT_CONTACT_DETAILS}
            data={{
              contactId: this.props.match.params.contactId,
              orgId: hardCodedOrgId
            }}
          />
        ) : (
          ""
        )}
        {this.props.match &&
        this.props.match.params &&
        this.props.match.params.contactId != undefined ? (
          <ContactViewOptions contactId={this.props.match.params.contactId} disabled={this.props.disabled}  contactDetails={this.props.match.params.contactDetails}/>
        ) : (
          <ContactViewOptions />
        )}
        <GridContainer justify="center">
          <GridItem>
            {this.props.match &&
            this.props.match.params &&
            this.props.match.params.contactId != undefined ? (
              <Button
                color="rose"
                onClick={() => {
                 this.props.dispatch(updateCustomerWithDispatch(this.props.callApi, this.props.contactDetails));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                color="rose"
                onClick={() => {
                  createCustomer(this.props.callApi, this.props);
                }}
              >
                Create Customer
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactDetails: state.pwReducer.contactDetails,
    orgId: hardCodedOrgId,
    userId: state.pwReducer.selfProfile.userId,
    disabled: (state.pwReducer.contactDetails && state.pwReducer.contactDetails.contactType == 2) ? true : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(ContactView));
