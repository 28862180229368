import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import { paths } from "variables/projPaths.jsx";
import { changeUrl, viewButton, deleteButton, orderStatusFromType } from "variables/coreWeb.jsx";
import { labelFromType, hardCodedOrgId } from "variables/project.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import * as makeApiCall from 'actions/makeApiCall';
import {bindActionCreators} from 'redux';

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import Clear from "@material-ui/icons/Clear";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle
};

const deleteStaff= function(callMe, staffId) {
  console.log(staffId)
  var payLoad = {};
  payLoad.orgId = hardCodedOrgId;
  payLoad.staffId = staffId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Staff",
    payLoad,
    actions.cw.API_ANIMAL_DELETE
  );
};

class OrgStaffList extends React.Component {
  render() {
    const { classes } = this.props;
    const fillButtons = function(theProps, staffId) {
      return (
        <div>
          <Button
            className={classes.actionButton}
            color="info"
            size="sm"
            href={paths.staffView +  "/" + staffId}
          >
            <Visibility className={classes.icon} />
          </Button>
          {/* <Button
            className={classes.actionButton}
            color="danger"
            size="sm"
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedStaffId",
                value: staffId
              });
            }}
          >
            <Clear className={classes.icon} />
          </Button> */}
        </div>
      );
    };


    return (
      <GridContainer justify={"center"}>
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Users"}
          afterActionHandler={actions.cw.GOT_ALL_ORG_USERS}
          data={{
            orgId: hardCodedOrgId
          }}
        />

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <SupervisorAccount />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Staff List
                <small> - manage your staff</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.props.userList.map((prop, key) => {
                  return {
                    id: key,
                    name: prop.contactList[0].contactFullName,
                    position: labelFromType(prop[1]),
                    status: orderStatusFromType(prop[2]),
                    age: prop[3],
                    actions: fillButtons(this.props, prop.contactList[0].contactId),
                    workingFor: prop[2] != 1 ? "Name" : "Jeffy",
                    totalValue: "100$",
                    role: "Vet",
                    futureApp: 10,
                    vacationDays: 3
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Staff Name",
                    accessor: "name"
                  },

                  /*{
                    Header: "Status",
                    accessor: "status"
                  },*/

                  {
                    Header: "Role",
                    accessor: "role"
                  },



                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {this.props.selectedStaffId ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-200px" }}
              title="Are you sure?"
              onConfirm={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedStaffId",
                  value: undefined
                }) ||
                deleteStaff(
                  this.props.callApi,
                  this.props.selectedStaffId
                ) 
              }}
              onCancel={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedStaffId",
                  value: undefined
                });
              }}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            >
              Deleting a staff is permanent
            </SweetAlert>
          ) : (
            ""
          )}

      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    userList: state.pwReducer.userList || [],
    selectedStaffId: state.pwReducer.selectedStaffId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    raiseAction: payload => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgStaffList));
