import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { useHistory } from "react-router-dom";
import { hardCodedOrgId } from "variables/project.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { paths } from "variables/projPaths.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import Button from "components/CustomButtons/Button.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deleteServiceWithDispatch } from "../../variables/thunks";
import { getActiveOrg } from "../../variables/coreWeb";

const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const OrgServices = function(props) {
  const { classes } = props;
  const history = useHistory();

  const loadCampaign = function(serviceId) {
    history.push("/" + paths.serviceView + "/" + serviceId);
  };

  const fillButtons = function(theProps, serviceId) {
    return (
      <div>
        <Button
          className={classes.actionButton}
          color="info"
          size="sm"
          onClick={() => {
            loadCampaign(serviceId);
          }}
        >
          <Visibility className={classes.icon} />
        </Button>
        <Button
          className={classes.actionButton}
          color="danger"
          size="sm"
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedseviceId",
              value: serviceId,
            });
          }}
        >
          <Clear className={classes.icon} />
        </Button>
      </div>
    );
  };

  const createNew = function handleClick() {
    history.push(paths.campaignNew);
  };

  return (
    <div>
      <GridContainer justify={"center"}>
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Services"}
          afterActionHandler={actions.cw.GOT_ALL_SERVICES_BY_ORG_ID}
          data={{
            orgId: getActiveOrg(),
          }}
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Button color="rose" onClick={createNew}>
            Create New Campaign
          </Button>
        </GridItem>
      </GridContainer>
      <h4 className={classes.cardIconTitle}>
        Your Campaigns
        <small> - The services you offer your clients</small>
      </h4>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <ReactTable
                data={props.serviceCompleteList.map((prop, key) => {
                  return {
                    id: key,
                    name: prop.name,
                    // position: labelFromType(prop[1]),
                    // status: orderStatusFromType(prop[2]),
                    // age: prop[3],
                    actions: fillButtons(props, prop.serviceId),
                    // workingFor: prop[2] != 1 ? '????' : "Coca Cola",
                    totalValue: "100$",
                    isPlatformSpecific: prop.isPlatformSpecific,
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Campaign Name",
                    accessor: "name",
                    sortable: false,
                    filterable: false,
                  },

                  /*{
                    Header: "User Created",
                    accessor: "isPlatformSpecific"
                  },*/

                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {props.selectedseviceId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              props.raiseAction({
                fieldIdentifier: "selectedseviceId",
                value: undefined,
              }) ||
                props.dispatch(
                  deleteServiceWithDispatch(
                    props.callApi,
                    props.selectedseviceId
                  )
                );
            }}
            onCancel={() => {
              props.raiseAction({
                fieldIdentifier: "selectedseviceId",
                value: undefined,
              });
            }}
            confirmBtnCssClass={
              props.classes.button + " " + props.classes.success
            }
            cancelBtnCssClass={
              props.classes.button + " " + props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a service is permanent
          </SweetAlert>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    serviceCompleteList: state.pwReducer.serviceCompleteList || [],
    selectedseviceId: state.pwReducer.selectedseviceId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgServices));
