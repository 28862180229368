import { hardCodedOrgId } from "variables/project.jsx";
import * as actions from "actions/projectActionTypes";
import { getActiveOrg } from "./coreWeb";

const createPatient= function(callMe,theProps)
{
  var payLoad = {};
  console.log(theProps.animalDetails);
  payLoad.animalBreed = 2;
  payLoad.animalSpecies = theProps.animalDetails.animalSpecies;
  payLoad.bloodGroup = theProps.animalDetails.bloodGroup;
  payLoad.dateOfBirth = theProps.animalDetails.dateOfBirth;
  payLoad.deceasedDate = "2048-12-31T16:00:00.000Z";
  payLoad.deceasedStatus = 2;
  payLoad.desexedStatus = theProps.animalDetails.desexedStatus;
  payLoad.gender = theProps.animalDetails.gender;
  payLoad.insuranceReferenceNumber = theProps.animalDetails.insuranceReferenceNumber;
  payLoad.isActive = 2;
  payLoad.mainColour = "#FF0000";
    payLoad.microchipId = theProps.animalDetails.microchipId;
  payLoad.name = theProps.animalDetails.name;
  payLoad.orgId = hardCodedOrgId;
  payLoad.passportNumber = theProps.animalDetails.passportNumber;
  payLoad.secondaryColour = "#00ff00";

  // payLoad.functionName = "createPatient";
  callMe.req(
    localStorage.getItem('access_token'),
     "Create_Org_Animal", 
     payLoad, 
     actions.cw.API_ANIMAL_CREATE);
}

const createCustomer= function(callMe,theProps)
  {
    var payLoad = {};
    console.log(theProps);
  payLoad.contactAcquisition = 2;
  payLoad.contactFirstName = theProps.contactDetails.contactFirstName;
  payLoad.contactLastName = theProps.contactDetails.contactLastName;
  payLoad.contactTitle = 2;
    payLoad.contactType = 3;
  payLoad.emailAddress = theProps.contactDetails.emailAddress;
  payLoad.orgId = theProps.orgId;
  payLoad.orgName = theProps.contactDetails.orgName;
  payLoad.userId = theProps.userId;

    callMe.req(
      localStorage.getItem('access_token'),
       "Create_Org_Contact", 
       payLoad, 
       actions.cw.API_CUSTOMER_CREATE);
  }

  const createResources= function(callMe,theProps)
  {
    var payLoad = {};
    console.log(theProps);
    payLoad.allowsOverlaps = 2;
    payLoad.depthUnitType = 2;
    payLoad.depthValue = 0;
    payLoad.heightUnitType = 2;
    payLoad.heightValue = 0;
    payLoad.latitude = 51.50722;
    payLoad.longitude = -0.1275;
    payLoad.maxAppointmentDuration = 7200000;
    payLoad.maxAppointmentFutureTimeInMs = 2419000000;
    payLoad.maxDailyUserSlots = 2;
    payLoad.maxExceptionDuration = 604800000;
    payLoad.orgId = hardCodedOrgId;
    payLoad.resourceName = theProps.resourceDetails.resourceName;
    payLoad.slotDuration = 10800000;
    payLoad.timeAllocationType = 4;
    payLoad.timeZoneIANA = "";
    payLoad.userId = 0;
    payLoad.widthUnitType = 2;
    payLoad.widthValue = 0;

    callMe.req(
      localStorage.getItem('access_token'),
       "Create_Resource", 
       payLoad, 
       actions.cw.API_RESOURCE_CREATE);
  }

  const createServices= function(callMe,theProps)
  {
    var payLoad = {};
    console.log(theProps);
    payLoad.description = theProps.description;
    payLoad.isPlatformSpecific = 3;
    payLoad.monetaryAmount = 0;
    payLoad.monetaryCurrency = 840;
    payLoad.name = theProps.name;
    payLoad.orgId = hardCodedOrgId;
    payLoad.taxRate = 1;

    callMe.req(
      localStorage.getItem('access_token'),
       "Create_Service", 
       payLoad, 
       actions.cw.API_SERVICE_CREATE);
  }

  const createProduct= function(callMe,theProps)
  {
    var payLoad = {};
    console.log(theProps);
    payLoad.orgId = hardCodedOrgId;
    payLoad.productName = theProps.productName;
    payLoad.purchasePrice1 = {
      monetaryAmount: theProps.purchasePrice.monetaryAmount,
      monetaryCurrency: 840
    };
    payLoad.salesPrice1 = {
      monetaryAmount: theProps.salesPrice.monetaryAmount,
      monetaryCurrency: 840  };
    payLoad.purchaseTaxRate = theProps.purchaseTaxRate;
    payLoad.salesTaxRate = theProps.salesTaxRate;
    payLoad.systemProductCode = "";
    payLoad.userProductCode = "";

    callMe.req(
      localStorage.getItem('access_token'),
       "Create_Product", 
       payLoad, 
       actions.cw.API_PRODUCT_CREATE);
  }

  const createStaff= function(callMe,theProps)
  {
    var payLoad = {};
    console.log(theProps);
    payLoad.contactFirstName = theProps.contactFirstName;
    payLoad.contactLastName = theProps.contactLastName;
    payLoad.emailAddress = theProps.emailAddress;
    payLoad.orgId = hardCodedOrgId;

    callMe.req(
      localStorage.getItem('access_token'),
       "Create_Member", 
       payLoad, 
       actions.cw.API_MEMBER_CREATE
    );
};

const createCustomerAddress = function(callMe, theProps) {
  var payLoad = {};
  console.log(theProps);
  payLoad.address1 = theProps.addressDetails.line1;
  payLoad.address2 = theProps.addressDetails.line2;
  payLoad.addressType = 2;
  payLoad.attention = "";
  payLoad.city = theProps.addressDetails.city;
  payLoad.contactId = theProps.contactDetails.contactId;
  payLoad.country = 2;
  payLoad.creatorId = 3;
  payLoad.isActive = 3;
  payLoad.isBillingAddress = false;
  payLoad.orgId = hardCodedOrgId;
  payLoad.town = theProps.addressDetails.town;
  payLoad.userId = theProps.contactDetails.userId;
  payLoad.zipcode = theProps.addressDetails.zippost;

  callMe.req(
    localStorage.getItem("access_token"),
    "Create_Address",
    payLoad,
    actions.cw.API_ADDRESS_CREATE
  );
};

const createMedicalNote = function(callMe, theProps, animalId) {
  var payLoad = {};
  console.log(theProps);
  var dateTime = theProps.date
    .hour(theProps.time.hour())
    .minute(theProps.time.minute());

  var noteDesc
if(theProps.medicalNoteType == 9) {
  noteDesc = theProps.weightMeasurement + " " + theProps.weightUnits
} else if (theProps.medicalNoteType == 10) {
  noteDesc = theProps.heightMeasurement + " " + theProps.heightUnits
}  else if (theProps.medicalNoteType == 12) {
  noteDesc = theProps.temperatureMeasurement + " " + theProps.temperatureUnits
} else if (theProps.medicalNoteType == 13) {
  noteDesc = theProps.pulseMeasurement + " beats per minute"
} else if (theProps.medicalNoteType == 14) {
  noteDesc = theProps.respirationMeasurement + " breaths per minute"
} else {
  noteDesc = theProps.noteDescription
}

  payLoad.createdOn_UTC = dateTime.toISOString();
  payLoad.medicalNoteType = theProps.medicalNoteType;
  payLoad.medicalRecordId = parseInt(animalId);
  payLoad.noteDescription = noteDesc;
  payLoad.orgId = hardCodedOrgId;

  console.log(payLoad);
  callMe.req(
    localStorage.getItem("access_token"),
    "Create_MedicalNote",
    payLoad,
    actions.cw.API_MEDICAL_CREATE
  );
};

export {
  createPatient,
  createCustomer,
  createResources,
  createServices,
  createProduct,
  createStaff,
  createCustomerAddress,
  createMedicalNote
};
