import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ReduxCoreSelect from "./ReduxCoreSelect";

class CoreQuantity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ReduxCoreSelect placeholder="Quantity" />;
  }
}

export default withStyles(userProfileStyles)(CoreQuantity);
