import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import { orderStatusFromType } from "variables/coreWeb.jsx";
import { dataTable5 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { hardCodedOrgId } from "variables/project.jsx";
import * as actions from "actions/projectActionTypes";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as makeApiCall from 'actions/makeApiCall';

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    const fillButtons = [
      { color: "info", icon: Visibility },
      { color: "danger", icon: Close }
    ].map((prop, key) => {
      return (
        <Button color={prop.color} className={classes.actionButton} key={key}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });

    return (
      <GridContainer>
        <CoreApiReq
              endpointToCall={"Read_All_Organisation_Invoices_By_OrgID"}
              afterActionHandler={actions.cw.GOT_ALL_ORG_INVOICES_BY_ORG_ID}
              data={{
                orgId: hardCodedOrgId
              }}
        />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Your Invoices
                <small> - Existing invoices</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.props.listOfInvoices.map((prop, key) => {
                  return {
                    id: key,
                    name: prop[0],
                    position: labelFromType(prop[1]),
                    status: orderStatusFromType(prop[2]),
                    age: prop[3],
                    actions: fillButtons,
                    workingFor: prop[2] != 1 ? "????" : "Coca Cola",
                    totalValue: "100$"
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Invoice Id",
                    accessor: "name"
                  },
                  {
                    Header: "Invoice Date",
                    accessor: "name"
                  },
                  {
                    Header: "Customer Name",
                    accessor: "workingFor"
                  },
                  {
                    Header: "Total Value",
                    accessor: "totalValue"
                  },
                  {
                    Header: "Status",
                    accessor: "status"
                  },

                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}



function mapStateToProps(state) {
  return {
    listOfInvoices: state.pwReducer.listOfInvoices || []
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    makeApiCall: bindActionCreators(makeApiCall, dispatch)
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(userProfileStyles)(InvoiceList));
