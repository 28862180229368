import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";

import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ReduxCoreSelect from "./ReduxCoreSelect";

import * as actions from "actions/projectActionTypes";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...userProfileStyles
};

var calcTheOptions = function(
  start,
  stop,
  lastOptionTextEnd,
  useValueNotIndex
) {
  var rows = [];
  var index = 0;
  for (var i = start; i <= stop; i++) {
    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    rows.push(
      <MenuItem value={useValueNotIndex ? i : index} key={i}>
        {i == stop ? i.toString() + lastOptionTextEnd : i.toString()}
      </MenuItem>
    );
    index++;
  }
  return rows;
};

class NumberRangeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <ReduxCoreSelect
          placeholder={this.props.placeholder}
          options={calcTheOptions(
            this.props.start,
            this.props.stop,
            this.props.lastOptionTextEnd || "",
            this.props.useValueNotIndex
          )}
          updateAction={
            this.props.actionToRaise || actions.cw.UPREDUXSELECTINPUT
          }
          fieldIdentifier={this.props.fieldIdentifier || "numberRangeSelect"}
          inputProps={this.props.inputProps}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default withStyles(userProfileStyles)(NumberRangeSelect);
