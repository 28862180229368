export default {
  selfProfile: {
    registrationType: 1,
  },
  orgDetails: {},
  selfServiceOrderList: [],
  orgList: [],
  serviceFulfilmentConfig: {},
  mandatoryResources: 0,
  optionalResources: 0,
  mandatoryStaff: 0,
  optionalStaff: 0,
};
