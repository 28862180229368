import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import Check from "@material-ui/icons/Check";
import { connect } from "react-redux";

import { dataTable5, statusFromType } from "variables/general.jsx";
import { svRPA } from "variables/project.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { changeUrl, viewButton, coreWebStyles } from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...userProfileStyles,
  ...coreWebStyles
};

class CoreTitleCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
       <Card chart>
              <CardHeader color={this.props.color || "warning"}>
                <h1 className={classes.cardHeaderCenter}>{this.props.value}</h1>
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>{this.props.title}</h4>
                <p className={classes.cardCategory}>
                  {this.props.subtitle}
                </p>
              </CardBody>
            </Card>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CoreTitleCard));
