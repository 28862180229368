import React from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import YouTube from "react-youtube";
import FeedItem from "../FeedItem";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";

import * as makeApiCall from "actions/makeApiCall";

const feedStyle = {};
class CoreApiReq extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // HERE WE ARE TRIGGERING THE ACTION
    console.log(
      this.props.endpointToCall + "    " + localStorage.getItem("access_token")
    );
    this.props.makeApiCall.req(
      localStorage.getItem("access_token"),
      this.props.endpointToCall,
      this.props.data,
      this.props.afterActionHandler
    );
  }

  render() {
    const { classes } = this.props;
    return <div />;
  }
}

CoreApiReq.propTypes = {
  endpointToCall: PropTypes.string,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    makeApiCall: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(feedStyle)(CoreApiReq));
