import Dashboard from "views/Dashboard/Dashboard.jsx";
import Charts from "views/Charts/Charts.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";

import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import AccountBox from "@material-ui/icons/AccountBox";
import Equalizer from "@material-ui/icons/Equalizer";
import Pets from "@material-ui/icons/Pets";
import Face from "@material-ui/icons/Face";
import Event from "@material-ui/icons/Event";
import Settings from "@material-ui/icons/Settings";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Ballot from "@material-ui/icons/Ballot";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Category from "@material-ui/icons/Category";
import AnimalList from "components/CoreWeb/AnimalList.jsx";
import CustomerList from "components/CoreWeb/CustomerList.jsx";
import InvoiceList from "components/CoreWeb/InvoiceList.jsx";
import AppointmentList from "components/CoreWeb/AppointmentList.jsx";
import OrgServices from "components/CoreWeb/OrgServices.jsx";
import OrgProducts from "components/CoreWeb/OrgProducts.jsx";
import OrgInventoryList from "components/CoreWeb/OrgInventoryList.jsx";
import OrgResourceList from "components/CoreWeb/OrgResourceList.jsx";
import OrgStaffList from "components/CoreWeb/OrgStaffList.jsx";
import CreateCustomer from "views/Forms/CreateCustomer.jsx";
import OrganisationList from "components/CoreWeb/OrganisationList.jsx";
import CreateAppointment from "views/Forms/CreateAppointment.jsx";
import CreateInvoice from "views/Forms/CreateInvoice.jsx";
import CreateService from "views/Forms/CreateService.jsx";
import CreateProduct from "../views/Forms/CreateProduct.jsx";
import CreateResource from "../views/Forms/CreateResource.jsx";
import CreateStaffMember from "../views/Forms/CreateStaffMember.jsx";
import CompanyProfile from "../views/Pages/CompanyProfile.jsx";
import CreateInventory from "../views/Forms/CreateInventory.jsx";
import ContactView from "components/CoreWeb/ContactView.jsx";
import ServiceView from "../components/CoreWeb/ServiceView.jsx";
import ViewProduct from "../components/CoreWeb/ViewProduct.jsx";
import PricingView from "../components/CoreWeb/PricingView.jsx";
import { paths } from "../variables/projPaths.jsx";
import Logout from "components/CoreWeb/Containers/Logout.jsx";
import ServiceOrderList from "components/CoreWeb/ServiceOrderList.jsx";
import ServiceOrderOrgList from "components/CoreWeb/ServiceOrderOrgList.jsx";
import ComingSoonStep from "../components/CoreWeb/ComingSoonStep.jsx";
import MakeServiceOrder from "components/CoreWeb/MakeServiceOrder.jsx";
import WorkModeSfc from "components/ProjectWeb/WorkModeSfc.jsx";
import LoginCallback from "components/CoreWeb/LoginCallback.jsx";

var dashRoutes = [
  {
    path: "/app/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
  },
  /*
  {
    collapse: true,
    path: "/metrics",
    name: "Metrics",
    state: "openMetrics",
    icon: Equalizer,
    views: [
      {
        path: "/metrics/your-metrics",
        name: "Your Clinic Data",
        mini: "",
        component: Charts
      }
    ]
  },*/
  {
    collapse: true,
    path: "/customers",
    name: "Fans",
    state: "openCust",
    icon: Face,
    registrationType: 4,
    views: [
      /*{
        path: "/customers/createnew",
        name: "Create New Customer",
        mini: "",
        component: CreateCustomer
      },*/
      {
        path: "/customers/existing",
        name: "Your Fans",
        mini: "",
        component: CustomerList,
        registrationType: 4,
      },
      {
        path: "/customers/view/:contactId",
        name: "View Customer",
        mini: "",
        component: ContactView,
        hideFromSideBar: true,
        registrationType: 4,
      },
    ],
  },
  /*{
    collapse: true,
    path: "/invoices",
    name: "Invoices",
    state: "openInvo",
    icon: EuroSymbol,
    views: [
      {
        path: "/customers/newInvoice",
        name: "Create New Invoice",
        mini: "",
        component: CreateInvoice
      },
      {
        path: "/customers/currentInvoices",
        name: "Existing Invoices",
        mini: "",
        component: InvoiceList
      }
    ]
  },
*/
  /*
  {
    collapse: true,
    path: "/schedule",
    name: "Schedule",
    state: "openApp",
    icon: Event,
    views: [
      {
        path: "/schedule/calendar",
        name: "Clinic Calendar",
        mini: "",
        component: Calendar
      },
      {
        path: "/schedule/createAppointment",
        name: "Create New Appointment",
        mini: "",
        component: CreateAppointment
      },
      {
        path: "/schedule/appointments",
        name: "Appointments",
        mini: "",
        component: AppointmentList
      }
    ]
  },
*/
  {
    collapse: true,
    path: "/services",
    name: "Loyalty",
    state: "openServices",
    icon: ShoppingCart,
    registrationType: 4,
    views: [
      {
        path: paths.campaignNew,
        name: "New Campaign",
        mini: "",
        component: CreateService,
        hideFromSideBar: true,
      },
      {
        path: "/campaign/view/:serviceId",
        name: "View Campaign",
        mini: "",
        component: ServiceView,
        hideFromSideBar: true,
      },
      {
        path: "/campaign/list",
        name: "Your Campaigns",
        mini: "",
        component: OrgServices,
      },
      {
        path: "/services/createFastServiceFulfilment",
        name: "Work Mode",
        mini: "",
        component: WorkModeSfc,
      },
      {
        path: "/services/serviceOrders",
        name: "Loyalty Claims",
        mini: "",
        component: ServiceOrderList,
      },
      {
        path: "/loyalty/view/:orgId/:serviceId/:serviceFulfilmentConfigId",
        name: "View Loyalty",
        mini: "",
        component: PricingView,
        hideFromSideBar: true,
      },
      {
        path: paths.pricingCreate + "/:serviceId",
        name: "Add Pricing",
        mini: "",
        component: PricingView,
        hideFromSideBar: true,
      },
      {
        path: paths.pricingCreate + "/:serviceId/:pricingId",
        name: "View Pricing",
        mini: "",
        component: PricingView,
        hideFromSideBar: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/serviceOrders",
    name: "Loyalty",
    state: "openServiceOrders",
    icon: Face,
    registrationType: 3,
    views: [
      {
        path: "/serviceOrders/list",
        name: "Your Points",
        mini: "",
        component: ServiceOrderList,
      },
      {
        path: "/serviceOrders/orgs",
        name: "Your Brands",
        mini: "",
        component: ServiceOrderOrgList,
      },
      {
        path: paths.pricingCreate + "/:serviceId/:pricingId",
        name: "Claim Loyalty",
        mini: "",
        component: MakeServiceOrder,
      },
    ],
  },
  /*
  {
    collapse: true,
    path: "/inventory",
    name: "Inventory",
    state: "openInventory",
    icon: Ballot,
    views: [
      {
        path: "/inventory/createNew",
        name: "Add New Product",
        mini: "",
        component: CreateProduct
      },
      {
        path: "/inventory/products",
        name: "Products",
        mini: "",
        component: OrgProducts
      },
      {
        path: "/inventory/view/:productId",
        name: "View Product",
        mini: "",
        component: ViewProduct,
        hideFromSideBar: true
      },
      {
        path: "/inventory/addInventory",
        name: "Add Inventory",
        mini: "",
        component: CreateInventory
      },
      {
        path: "/inventory/manageStock",
        name: "View Stock Levels",
        mini: "",
        component: OrgInventoryList
      }
    ]
  },
  {
    collapse: true,
    path: "/resources",
    name: "Resources",
    state: "openResources",
    icon: Category,
    views: [
      {
        path: "/resources/createnew",
        name: "Add Resource",
        mini: "",
        component: CreateResource
      },
      {
        path: "/settings/existing",
        name: "Manage Resources",
        mini: "",
        component: OrgResourceList
      },
      {
        path: "/resources/view/:resourceId",
        name: "View Resource",
        mini: "",
        component: CreateResource,
        hideFromSideBar: true,
      },
    ]
  },
  {
    collapse: true,
    path: "/personell",
    name: "Personell",
    state: "openPersonell",
    icon: SupervisorAccount,
    views: [
      {
        path: "/personell/createnew",
        name: "Add Staff Member",
        mini: "",
        component: CreateStaffMember
      },
      {
        path: "/personell/existing",
        name: "Manage Staff",
        mini: "",
        component: OrgStaffList
      },
      {
        path: "/contact/view/:contactId",
        name: "View Staff",
        mini: "",
        component: ContactView,
        hideFromSideBar: true,
      },
    ]
  },*/
  /*{
    collapse: true,
    path: "/unavailable",
    name: "Unavailable Periods",
    state: "openUnav",
    icon: EventBusy,
    views: [
      
      {
        path: "/unavailable/createAbsence",
        name: "New Staff Absence",
        mini: "",
        component: CreateStaffAbsence
      },
      {
        path: "/unavailable/absences",
        name: " Absences",
        mini: "",
        component: AbsenceList
      }, 
      {
        path: "/unavailable/createException",
        name: "New Resource Exclusion",
        mini: "",
        component: CreateResourceExclusion
      },
      {
        path: "/unavailable/resExceptions",
        name: "Resource Exlusions",
        mini: "",
        component: ResourceExceptionList
      },   
      {
        path: "/unavailable/createClosure",
        name: "New Closure Period",
        mini: "",
        component: CreateOrgClosure
      },
      {
        path: "/unavailable/closures",
        name: "Clinic Closures",
        mini: "",
        component: OrgClosures
      },        
    ]
  },*/
  /*{
    collapse: true,
    path: "/insurance",
    name: "Insurance",
    state: "openInsurances",
    icon: ListAlt,
    views: [
      {
        path: "/insurance/clinic",
        name: "Clinic Insurance",
        mini: "",
        component: UserProfile
      },    
      {
        path: "/insurance/customer",
        name: "Customers Insurance",
        mini: "",
        component: UserProfile
      },      
    ]
  },*/
  {
    collapse: true,
    path: "/settings",
    name: "Brands",
    state: "openSettings",
    icon: Settings,
    registrationType: 4,
    views: [
      {
        path: "/organisation/list",
        name: "Your Brands",
        mini: "",
        component: OrganisationList,
      },
      {
        path: paths.viewOrganisation + "/:id",
        name: "Organization View",
        mini: "",
        component: CompanyProfile,
        hideFromSideBar: true,
      },
      {
        path: paths.customerLeaderboard + "/:id",
        name: "Organization Leaderboard",
        mini: "",
        component: OrganisationList,
        hideFromSideBar: true,
      },
      {
        path: paths.orgView,
        name: "Create Brand",
        mini: "",
        component: CompanyProfile,
        hideFromSideBar: true,
      },
      /*{
        path: "/settings/insurance",
        name: "Clinic Insurance",
        mini: "",
        component: CompanyProfile
      }*/
    ],
  },
  {
    collapse: true,
    path: "/profile",
    name: "Profile",
    state: "openProfile",
    icon: AccountBox,
    views: [
      {
        path: "/profile/user-profile",
        name: "Your Profile",
        mini: "",
        component: UserProfile,
      },
      /*{
        path: "/profile/user-feedback",
        name: "Your Feedback",
        mini: "",
        component: YourFeedback
      },
     */
    ],
  },
  {
    path: "/app/logout",
    name: "Logout",
    icon: DashboardIcon,
    component: Logout,
  },
  {
    path: "/loginCallback",
    name: "LoginCallBack",
    component: LoginCallback,
    hideFromSideBar: true,
  },
];
export default dashRoutes;
