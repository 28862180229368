import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CreateStaffMemberStep1 from "./WizardSteps/CreateStaffMemberStep1.jsx";
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import { hardCodedOrgId } from "variables/project.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { createStaff } from "../../variables/coreCreateRequests.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};


class CreateStaffMember extends React.Component {

  render() {
    return (
      <div>
        <CreateStaffMemberStep1></CreateStaffMemberStep1>
        <GridContainer justify="center" style={{ marginTop: "5%" }}>
          <GridItem>
            <Button
              color="rose"
              onClick={() => {
                createStaff(this.props.callApi, this.props);
              }}
            >
              Create Staff Member
            </Button>
          </GridItem>
        </GridContainer>
      </div>
      
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactFirstName: state.pwReducer.staffFirstName,
    contactLastName: state.pwReducer.staffLastName,
    emailAddress: state.pwReducer.staffEmailAddress,
    orgId: hardCodedOrgId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateStaffMember));
