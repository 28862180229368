import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { connect } from "react-redux";
import ReduxCardCustomInput from "components/CoreWeb/ReduxCardCustomInput";

class AddressViewOptions extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <h4>Address</h4>
        <ReduxCardCustomInput
          header={"Line 1"}
          fieldIdentifier={"addressDetails.line1"}
        />
        <ReduxCardCustomInput
          header={"Line 2"}
          fieldIdentifier={"addressDetails.line2"}
        />
        <ReduxCardCustomInput
          header={"Town"}
          fieldIdentifier={"addressDetails.town"}
        />
        <ReduxCardCustomInput
          header={"City"}
          fieldIdentifier={"addressDetails.city"}
        />
        <ReduxCardCustomInput
          header={"Zip/Postal Code"}
          fieldIdentifier={"addressDetails.zippost"}
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(AddressViewOptions));
