const cw = {
  dashboard: "/dashboard",
  orgView: "/app/profile/organisation-profile",
  serviceFulfilmentConfigView: "/app/campaign/editFulfilmentConfig",
  viewOrganisation: "/app/organisation-profile",
  customerView: "/app/customers/view",
  animalView: "/app/patients/view",
  resourceView: "/app/resources/view",
  productView: "/app/inventory/view",
  serviceView: "/app/campaign/view",
  staffView: "/app/contact/view",
  customerList: "/app/customers/existing",
  animalList: "/app/patients/existing",
  serviceList: "/app/services/existingServices",
  productList: "/app/inventory/products",
  resourceList: "/app/settings/existing",
  memberList: "/app/personell/existing",
  pricingView: "/app/loyalty/view",
  pricingCreate: "/app/loyalty/create",
  medicalRecordEntry: "/app/patient/medicalRecordEntry",
  addressDetails: "/app/contact/addressDetails",
  logout: "/logout",
  orgSignIn: "/app/organisation/list",
};

const pw = {
  ...cw,
  campaignNew: "/app/campaign/new",
  campaignView: "/app/campaign/wizard",
  makeApplication: "/app/campaign/makeApplication",
  customerLeaderboard: "/app/organisation-leaderboard",
};

var paths = { ...pw };
export { paths };
