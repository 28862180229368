import React, { useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import LocationOn from "@material-ui/icons/LocationOn";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import * as actions from "actions/projectActionTypes";

import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";

import { connect } from "react-redux";

const UserProfile = (props) => {

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <NavPills
            color="rose"
            alignCenter
            tabs={[
              {
                tabButton: "Settings",
                tabIcon: LocationOn,
                tabContent: (
                  <div>
                    <h4>
                      Your Details
                      <small> - Present yourself to others</small>
                    </h4>
                    <Card>
                      <CardBody>
                        <h4>Email address</h4>
                        <ReduxCustomInput
                          subTextLabel="(not modifiable)"
                          textValue={props.emailAddress}
                          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                          fieldIdentifier={
                            props.emailFieldIdentifier || "emailAddress"
                          }
                          disabled={true}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <h4>First Name</h4>
                        <ReduxCustomInput
                          subTextLabel="(required)"
                          textValue={props.contactFirstName}
                          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                          fieldIdentifier={
                            props.firstNameFieldIdentifier || "firstName"
                          }
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <h4>Last Name</h4>
                        <ReduxCustomInput
                          subTextLabel="(required)"
                          textValue={props.contactLastName}
                          updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                          fieldIdentifier={
                            props.lastNameFieldIdentifier || "lastName"
                          }
                        />
                      </CardBody>
                    </Card>
                  </div>
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );

}

function mapStateToProps(state) {
  return {
    userId: state.pwReducer.selfProfile.userId,
    contactFirstName: state.pwReducer.selfProfile.contactFirstName,
    contactLastName: state.pwReducer.selfProfile.contactLastName,
    emailAddress: state.pwReducer.selfProfile.emailAddress
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(UserProfile));
