import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { connect } from "react-redux";
import * as actions from "actions/projectActionTypes";
import * as raisers from "actions/projectRaisers";
import { getObjProp } from "variables/coreWeb.jsx";

const style = {};

class ReduxCustomInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    return (
      <CustomInput
        labelText={
          <span>
            {this.props.mainTextLabel} <small>{this.props.subTextLabel}</small>
          </span>
        }
        id="float"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => this.props.textChanged(event.target.value),
          value: this.props.textValue,
          multiline: true,
          rows: this.props.multiLine || 1,
          disabled: this.props.disabled || false,
          ...this.props.inputProps
        }}
        labelProps={{
          shrink: true
        }}
      />
    );
  }
}

ReduxCustomInput.defaultProps = {
  mainTextLabel: "",
  subTextLabel: ""
};

const mapStateToProps = (state, ownProps) => {
  return {
    textValue:
      ownProps.textValue ||
      getObjProp(state.pwReducer, ownProps.fieldIdentifier)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    textChanged: payload => {
      if (
        ownProps.updateAction === actions.cw.UPREDUXCUSTOMINPUT ||
        !ownProps.updateAction
      ) {
        var newPayload = {};
        newPayload.value = payload;
        newPayload.fieldIdentifier = ownProps.fieldIdentifier;
        dispatch(
          raisers.createActionGeneric(newPayload, actions.cw.UPREDUXCUSTOMINPUT)
        );
      } else {
        dispatch(raisers.createActionGeneric(payload, ownProps.updateAction));
      }
    }
  };
};

export default withStyles(style)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReduxCustomInput)
);
