import React from "react";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import { hardCodedOrgId } from "variables/project.jsx";
import * as makeApiCall from 'actions/makeApiCall';
import Button from "components/CustomButtons/Button.jsx";
import * as actions from 'actions/projectActionTypes';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class CreateServiceStep4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: ""
    };
  }

  
  
  
  render() {
    const { classes } = this.props;
    return (
      <div>
      
      
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    description: state.pwReducer.serviceDesc,
    isPlatformSpecific: 3,
    monetaryAmount: 0,
    monetaryCurrency: 840,
    name: state.pwReducer.serviceName,
    orgId: hardCodedOrgId,
    taxRate: 1,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(style)(CreateServiceStep4));
