import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import NumberRangeSelect from "components/CoreWeb/NumberRangeSelect";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

class MandatoryStaffSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <NumberRangeSelect
        placeholder="Select Number Of Mandatory Staff"
        start={this.props.start == undefined ? 0 : this.props.start}
        stop={this.props.stop == undefined ? 5 : this.props.stop}
        useValueNotIndex={true}
        fieldIdentifier={"mandatoryStaff"}
        actionToRaise={this.props.updateAction}
        disabled={this.props.disabled}
      />
    );
  }
}

export default withStyles(userProfileStyles)(MandatoryStaffSelect);
