import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import TagsInput from "react-tagsinput";
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import Favorite from "@material-ui/icons/Favorite";
import Edit from "@material-ui/icons/Edit";
import Star from "@material-ui/icons/Star";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ReactTable from "react-table";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger.jsx";
import Success from "components/Typography/Success.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Timeline from "@material-ui/icons/Timeline";
import Search from "@material-ui/icons/NearMe";
import { getActiveOrg } from "variables/coreWeb.jsx";
import Auth from "Auth/Auth";
import { REGISTRATION_CLIENT, REGISTRATION_VENDOR } from "variables/coreWeb";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { useHistory } from "react-router-dom";
import { paths } from "../../variables/projPaths";
import { useSelector, useDispatch } from "react-redux";
import {
  apiGetDashboard,
  apiGetSelfProfile,
  getToken,
} from "../../variables/newApi";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";

import ReadServiceOrdersSelf from "components/CoreWeb/Containers/ReadServiceOrdersSelf";

import {
  grayColor,
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import sellrepDashboardStyle from "assets/jss/material-dashboard-pro-react/views/sellrepDashboardStyle";

import genericHttpHandler from "actions/genericHttpHandler";

import avatar from "assets/img/faces/avatar.jpg";
import priceImage1 from "assets/img/coco-cola.jpg";
import priceImage2 from "assets/img/tata-logo.jpg";
import priceImage3 from "assets/img/spotify-logo.jpg";
import priceImage4 from "assets/img/spotifysocialcard.jpg";
import priceImage5 from "assets/img/amazonjobs.jpg";
import priceImage6 from "assets/img/spotifyAd.png";
import priceImage7 from "assets/img/cocacolavr.jpg";
import priceImage8 from "assets/img/hmcoupon.jpg";
import priceImage9 from "assets/img/icalilje.jpg";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");

const defaultMapOptions = {
  fullscreenControl: false,
};

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={{ lat: 59.334591, lng: 18.06324 }}
      defaultOptions={{
        scrollwheel: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }}
    >
      <Marker
        position={{ lat: 59.313431, lng: 18.035469 }}
        options={{
          fillColor: roseColor,
          strokeColor: roseColor,
          fillOpacity: 1,
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
        radius={100}
      />
      <Marker
        position={{ lat: 59.33443, lng: 18.03249 }}
        options={{
          fillColor: roseColor,
          strokeColor: roseColor,
          fillOpacity: 1,
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
        radius={100}
      />
      <Marker
        position={{ lat: 59.33832, lng: 18.09015 }}
        options={{
          fillColor: roseColor,
          strokeColor: roseColor,
          fillOpacity: 1,
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
        radius={100}
      />
    </GoogleMap>
  ))
);

const Dashboard = function(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileType = useSelector((state) => {
    return state.pwReducer.selfProfile.registrationType;
  });
  useEffect(() => {
    const accessToken = getToken();
    if (accessToken && accessToken.length > 0) {
      dispatch(apiGetSelfProfile());
    } else {
      genericHttpHandler({ status: 401 });
    }
  }, []);

  useEffect(
    () => {
      if (profileType > 1) {
        const accessToken = getToken();
        if (accessToken && accessToken.length > 0) {
          var org = getActiveOrg(() => {});

          if (profileType === REGISTRATION_CLIENT || profileType === 2) {
            dispatch(apiGetDashboard());
          } else {
            if (!org) {
              history.push(paths.orgSignIn);
            }
          }
        }
      }
    },
    [profileType > 1]
  );

  const { classes } = props;

  const photo = classes.photo;

  const feedItemsToRender = [
    {
      icon: Star,
      orgName: "Amazon.com",
      cardType: "Recruit",
      pathCross: "Crossed Paths 2 Times",
      lastTime: "Now",
      briefTitle: "Java Developer",
      cardImage: priceImage5,
      engagement: "warning",
    },
    {
      icon: Close,
      orgName: "Spotify AB",
      cardType: "Discover",
      pathCross: "Crossed Paths 4 Times",
      lastTime: "Now",
      briefTitle: "Our offer to you",
      cardImage: priceImage6,
      engagement: "warning",
    },
    {
      icon: Close,
      orgName: "H&M - Stureplan",
      cardType: "Discover",
      pathCross: "Crossed Paths 4 Times",
      briefTitle: "20% of for locals",
      lastTime: "Now",
      cardImage: priceImage8,
      engagement: "warning",
    },

    {
      icon: Close,
      orgName: "Coca Cola",
      cardType: "Engagement",
      pathCross: "Crossed Paths 4 Times",
      briefTitle: "We saw you go past",
      lastTime: "Now",
      cardImage: priceImage1,
      engagement: "warning",
    },
    {
      icon: Close,
      orgName: "ICA - Liljeholmen",
      cardType: "Discover",
      pathCross: "Crossed Paths 4 Times",
      briefTitle: "New store section opened this week",
      lastTime: "Now",
      cardImage: priceImage9,
      engagement: "warning",
    },
    {
      icon: Star,
      orgName: "Tata Consulting",
      cardType: "Recruit",
      pathCross: "Crossed Paths 2 Times",
      briefTitle: "Recruiting Business consultants",
      lastTime: "Now",
      cardImage: priceImage2,
      engagement: "warning",
    },
    {
      icon: Favorite,
      orgName: "Spotify AB",
      cardType: "Engagement",
      pathCross: "Crossed Paths 2 Times",
      briefTitle: "Your always close to our hearts",
      lastTime: "Now",
      cardImage: priceImage3,
      engagement: "",
    },
    {
      icon: Favorite,
      orgName: "Coca Cola",
      cardType: "Engagement",
      pathCross: "Crossed Paths 2 Times",
      briefTitle: "The holidays are coming",
      lastTime: "Now",
      cardImage: priceImage7,
      engagement: "",
    },
  ];

  return (
    <div>
      {props.selfProfile.registrationType == REGISTRATION_VENDOR ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10} lg={10}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Icon>assignment</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Current Campaigns</p>
                    <h3 className={classes.cardTitle}>
                      {props.recvdQuotationCount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Icon>visibility</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Total Fans</p>
                    <h3 className={classes.cardTitle}>
                      {props.submittedQuotationsCount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Icon>euro_symbol</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Total Loyalty</p>
                    <h3 className={classes.cardTitle}>
                      {props.postedRFPCount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats />
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      ) : (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10} lg={10}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Icon>assignment</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}># of Brands</p>
                    <h3 className={classes.cardTitle}>
                      {props.recvdQuotationCount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Icon>visibility</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Highest FanRank</p>
                    <h3 className={classes.cardTitle}>
                      {props.submittedQuotationsCount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Icon>euro_symbol</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Total Loyalty</p>
                    <h3 className={classes.cardTitle}>
                      {props.postedRFPCount}
                    </h3>
                  </CardHeader>
                  <CardFooter stats />
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      )}

      {props.selfProfile.registrationType == REGISTRATION_VENDOR ? (
        <div>
          <h4>
            {" "}
            Recent Activity
            <small> - The last 5 people to redeem points</small>
          </h4>
          <Card>
            <CardBody>
              <ReactTable
                data={[].map((prop, key) => {
                  return {};
                })}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    sortable: false,
                  },
                  {
                    Header: "Redeemed",
                    accessor: "workingFor",
                    sortable: false,
                  },
                  {
                    Header: "Date",
                    accessor: "totalValue",
                    sortable: false,
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </div>
      ) : (
        <div>
          <h4>
            {" "}
            Recent Activity
            <small> - The last points you redeemed</small>
          </h4>
          <Card>
            <CardBody>
              <ReactTable
                data={[].map((prop, key) => {
                  return {};
                })}
                columns={[
                  {
                    Header: "Type",
                    accessor: "name",
                    sortable: false,
                  },
                  {
                    Header: "Brand",
                    accessor: "workingFor",
                    sortable: false,
                  },
                  {
                    Header: "Date",
                    accessor: "totalValue",
                    sortable: false,
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </div>
      )}

      {props.selfProfile.registrationType == REGISTRATION_VENDOR ? "" : ""}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selfProfile: state.pwReducer.selfProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(sellrepDashboardStyle)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
