import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Ballot from "@material-ui/icons/Ballot";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import { getActiveOrg } from "variables/coreWeb.jsx";
import { hardCodedOrgId } from "variables/project.jsx";
import { paths } from "variables/projPaths.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import * as makeApiCall from 'actions/makeApiCall';
import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import {bindActionCreators} from 'redux';


const style = {
  ...userProfileStyles,
  ...sweetAlertStyle
};

const deleteProduct = function(callMe, productId) {
  console.log(productId)
  var payLoad = {};
  payLoad.orgId = getActiveOrg();
  payLoad.productId = productId;

  callMe.req(
    localStorage.getItem("access_token"),
    "Delete_Product",
    payLoad,
    actions.cw.API_PRODUCT_DELETE
  );
};

class OrgProducts extends React.Component {
  render() {
    const { classes } = this.props;
    const fillButtons = function(theProps, productId) {
      return (
        <div>
          <Button
            className={classes.actionButton}
            color="info"
            size="sm"
            href={'/' + paths.productView + "/" + productId}
          >
            <Visibility className={classes.icon} />
          </Button>
          <Button
            className={classes.actionButton}
            color="danger"
            size="sm"
            onClick={() => {
              theProps.raiseAction({
                fieldIdentifier: "selectedProductId",
                value: productId
              });
            }}
          >
            <Clear className={classes.icon} />
          </Button>
        </div>
      );
    };
    
    return (
      <GridContainer justify="center">
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Products"}
          afterActionHandler={actions.cw.GOT_ALL_ORG_PRODUCTS}
          data={{
            orgId: hardCodedOrgId
          }}
        />

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Ballot />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Clinic Products
                <small> - The services you offer your clients</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.props.productList.map((prop, key) => {
                  return {
                    id: key,
                    productId: prop.productId,
                    actions: fillButtons(this.props, prop.productId),
                    totalValue: "100",
                    productName: prop.productName,
                    clinicCode: prop.userProductCode,
                    systemCode: prop.systemProductCode,
                    salesPrice: prop.salesTaxRate
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Product Id",
                    accessor: "productId"
                  },

                  {
                    Header: "Product Name",
                    accessor: "productName"
                  },
                  {
                    Header: "Clinic Code",
                    accessor: "clinicCode"
                  },
                  {
                    Header: "System Code",
                    accessor: "systemCode"
                  },
                  {
                    Header: "In Stock",
                    accessor: "totalValue"
                  },
                  {
                    Header: "Sales Price",
                    accessor: "salesPrice"
                  },
                  {
                    Header: "Status",
                    accessor: "status"
                  },

                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {this.props.selectedProductId ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-200px" }}
              title="Are you sure?"
              onConfirm={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedProductId",
                  value: undefined
                }) ||
                deleteProduct(
                  this.props.callApi,
                  this.props.selectedProductId
                ) 
              }}
              onCancel={() => {
                this.props.raiseAction({
                  fieldIdentifier: "selectedProductId",
                  value: undefined
                });
              }}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            >
              Deleting a product is permanent
            </SweetAlert>
          ) : (
            ""
          )}

      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    productList: state.pwReducer.productList || [],
    selectedProductId: state.pwReducer.selectedProductId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    raiseAction: payload => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(OrgProducts));
