import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput";

class ReduxCardCustomInput extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <h4>{this.props.header}</h4>
            <ReduxCustomInput fieldIdentifier={this.props.fieldIdentifier} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(ReduxCardCustomInput));
