import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateServiceStep1 from "./CreateServiceStep1.jsx";
import CreateServiceStep2 from "./CreateServiceStep2.jsx";
import CreateServiceStep4 from "./CreateServiceStep4.jsx";
import ComingSoonStep from "./ComingSoonStep.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//icon import
import Option from "@material-ui/icons/MoreHoriz";
import Price from "@material-ui/icons/LocalOffer";
import LocationOn from "@material-ui/icons/LocationOn";
import PrePaymentRequiredSelect from "components/CoreWeb/PrePaymentRequiredSelect";
import final from "@material-ui/icons/VerifiedUser";
import MandatoryStaffSelect from "components/CoreWeb/MandatoryStaffSelect";
import OptionalStaffSelect from "components/CoreWeb/OptionalStaffSelect";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PricingName from "./PricingName";
import SelectIsActive from "./SelectIsActive";
import { createServiceFulfilmentConfigComplete } from "variables/coreRequests.jsx";
import { generateResourceSfcMapRows } from "variables/coreWeb.jsx";

import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";

import MandatoryResourcesSelect from "components/CoreWeb/MandatoryResourcesSelect";
import OptionalResourcesSelect from "components/CoreWeb/OptionalResourcesSelect";
import ReactTable from "react-table";
import { getActiveOrg } from "../../variables/coreWeb.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class ServiceFulfilmentConfigResourceMappingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullFilmentConfigs: []
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CoreApiReq
              endpointToCall={"Read_All_Organisation_Resources"}
              afterActionHandler={actions.cw.GOT_ALL_ORG_RESOURCES}
              data={{
                orgId: getActiveOrg()
              }}
            />
            <CoreApiReq
              endpointToCall={"Read_All_Organisation_Users"}
              afterActionHandler={actions.cw.GOT_ALL_ORG_USERS}
              data={{
                orgId:  getActiveOrg()
              }}
            />

            <h4>Staff Members</h4>
            <Card>
              <CardBody>
                <h4>
                  Mandatory staff -{" "}
                  <small>
                    Number of mandatory staff who must be present to complete
                    the service
                  </small>
                </h4>
                <MandatoryStaffSelect
                  stop={this.props.mandatoryStaffRemainingCount}
                  disabled={this.props.disabled}
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4>
                  Optional staff -{" "}
                  <small>
                    Number of optional staff any of who could be present to
                    complete the service
                  </small>
                </h4>
                <OptionalStaffSelect
                  stop={this.props.optionalStaffRemainingCount}
                  disabled={this.props.disabled}
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <ReactTable
                  data={this.props.staffTableData}
                  filterable
                  columns={[
                    {
                      Header: "Requirement Type",
                      accessor: "reqType",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Staff Member",
                      accessor: "resourceSelect",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Duration in Minutes",
                      accessor: "durationSelect",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Sales Price",
                      accessor: "salesPrice",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
            <h4>Resources</h4>

            <Card>
              <CardBody>
                <h4>
                  Mandatory resources -{" "}
                  <small>
                    Number of mandatory resources which must be available to
                    complete the service
                  </small>
                </h4>

                <MandatoryResourcesSelect
                  stop={this.props.mandatoryResourcesRemainingCount}
                  disabled={this.props.disabled}
                />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h4>
                  Optional resources -{" "}
                  <small>
                    Number of optional resources any of which could be available
                    to complete the service
                  </small>
                </h4>
                <OptionalResourcesSelect
                  stop={this.props.optionalResourcesRemainingCount}
                  disabled={this.props.disabled}
                />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <ReactTable
                  data={this.props.resourceTableData}
                  filterable
                  columns={[
                    {
                      Header: "Requirement Type",
                      accessor: "reqType",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Resource Name",
                      accessor: "resourceSelect",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Duration in Minutes",
                      accessor: "durationSelect",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Sales Price",
                      accessor: "salesPrice",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function getAlreadySelected(stateLocation, prefixKey, maxCount) {
  var resp = [];
  for (var i = 0; i < maxCount; i++) {
    for (var l = 0; l < prefixKey.length; ++l) {
      var value = stateLocation[prefixKey[l] + "" + i.toString()];
      if (value) {
        resp.push(value);
      }
    }
  }
  return resp;
}

function resourceShortName(obj) {
  return obj ? obj.resourceShortName : "";
}

function resourceId(obj) {
  return obj ? obj.resourceId : -1;
}

function contactUserName(obj) {
  return obj ? obj.contactList[0].contactFullName : "";
}

function mapStateToProps(state, ownProps) {
  var currentResourceList = state.pwReducer.resourceCompleteList || [];
  var currentStaffList = state.pwReducer.userList || [];
  var currentlySelectedResources = getAlreadySelected(
    state.pwReducer,
    ["mandatoryResConfigResourceSelect", "optionalResConfigResourceSelect"],
    state.pwReducer.mandatoryResources
  );
  var currentlySelectedStaff = getAlreadySelected(
    state.pwReducer,
    ["mandatoryStaffConfigResourceSelect", "optionalStaffConfigResourceSelect"],
    state.pwReducer.mandatoryStaff
  );

  return {
    resourceCompleteList: currentResourceList,
    mandatoryResources: state.pwReducer.mandatoryResources,
    optionalResources: state.pwReducer.optionalResources,
    mandatoryStaff: state.pwReducer.mandatoryStaff,
    optionalStaff: state.pwReducer.optionalStaff,
    resourceTableData: state.pwReducer.resourceCompleteListOptions
      ? generateResourceSfcMapRows(
          state.pwReducer.mandatoryResources,
          "mandatoryResConfig",
          currentResourceList,
          true,
          currentlySelectedResources,
          state.pwReducer,
          resourceShortName,
          resourceId,
          ownProps.disabled
        ).concat(
          generateResourceSfcMapRows(
            state.pwReducer.optionalResources,
            "optionalResConfig",
            currentResourceList,
            false,
            currentlySelectedResources,
            state.pwReducer,
            resourceShortName,
            resourceId,
            ownProps.disabled
          )
        )
      : [],
    staffTableData: generateResourceSfcMapRows(
      state.pwReducer.mandatoryStaff,
      "mandatoryStaffConfig",
      currentStaffList,
      true,
      currentlySelectedStaff,
      state.pwReducer,
      contactUserName,
      resourceId,
      ownProps.disabled
    ).concat(
      generateResourceSfcMapRows(
        state.pwReducer.optionalStaff,
        "optionalStaffConfig",
        currentStaffList,
        false,
        currentlySelectedStaff,
        state.pwReducer,
        contactUserName,
        resourceId,
        ownProps.disabled
      )
    ),
    mandatoryResourcesRemainingCount:
      currentResourceList.length - (state.pwReducer.optionalResources || 0),
    optionalResourcesRemainingCount:
      currentResourceList.length - (state.pwReducer.mandatoryResources || 0),
    mandatoryStaffRemainingCount:
      currentStaffList.length - (state.pwReducer.optionalStaff || 0),
    optionalStaffRemainingCount:
      currentStaffList.length - (state.pwReducer.mandatoryStaff || 0)
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ServiceFulfilmentConfigResourceMappingView));
