// ##############################
// // // Dashboard View styles
// #############################

import {
    successColor,
    tooltip,
    cardTitle,
    drawerWidth,
    drawerMiniWidth,
    transition,
    boxShadow,
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    infoColor,
    warningColor,
    dangerColor,
    roseColor
  } from "assets/jss/material-dashboard-pro-react.jsx";

  
  import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";
  import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.jsx";
  import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
  
  const sellrepDashboardStyle = {
    ...hoverCardStyle,
    ...customCheckboxRadioSwitch,
    ...buttonGroupStyle,
     tooltip,
    cardTitle: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    cardProductTitle: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px",
      textAlign: "center"
    },
    cardCategory: {
      color: "#999999",
      fontSize: "14px",
      paddingTop: "10px",
      marginBottom: "0",
      marginTop: "0",
      margin: "0"
    },
    cardProductDesciprion: {
      textAlign: "center",
      color: "#999999"
    },
    stats: {
      color: "#999999",
      fontSize: "12px",
      lineHeight: "22px",
      display: "inline-flex",
      "& svg": {
        position: "relative",
        top: "4px",
        width: "16px",
        height: "16px",
        marginRight: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        position: "relative",
        top: "4px",
        fontSize: "16px",
        marginRight: "3px"
      }
    },
    productStats: {
      paddingTop: "7px",
      paddingBottom: "7px",
      margin: "0"
    },
    successText: {
      color: successColor
    },
    upArrowCardCategory: {
      width: 14,
      height: 14
    },
    underChartIcons: {
      width: "17px",
      height: "17px"
    },
    price: {
      color: "inherit",
      "& h4": {
        marginBottom: "0px",
        marginTop: "0px"
      }
    },
  


  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
  description: {
    maxWidth: "150px"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block"
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: "#3C4858"
  },
  tdNameSmall: {
    color: "#999999",
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important"
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0"
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  photo: {
    transition: "all 300ms linear",
    width: "34px",
    height: "34px",
    overflow: "hidden",
    float: "left",
    zIndex: "5",
    marginRight: "11px",
    borderRadius: "50%",
    marginLeft: "23px",
    ...boxShadow
  },
};
  export default sellrepDashboardStyle;
  