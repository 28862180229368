import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";

const bloodSelectOptions = [
  {
    name: "DEA 11",
    value: 1
  },
  {
    name: "DEA 11 Plus",
    value: 2
  },
  {
    name: "A",
    value: 3
  },
  {
    name: "B",
    value: 4
  },
  {
    name: "AB",
    value: 5
  },
  {
    name: "C",
    value: 6
  },
  {
    name: "D",
    value: 7
  },
  {
    name: "K",
    value: 8
  },
  {
    name: "P",
    value: 9
  },
  {
    name: "Q",
    value: 10
  },
  {
    name: "U",
    value: 11
  },
  {
    name: "T",
    value: 12
  },
  {
    name: "Not Applicable",
    value: 13
  }
];

class BloodGroup extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <h4>Blood Group</h4>
        <ReduxCoreSelect
        options={this.props.bloodSelectionOptions}
        fieldIdentifier={this.props.fieldIdentifier || "selectedBlood"}
        updateAction={actions.cw.UPREDUXSELECTINPUT}
      />
      </div>
      
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    bloodSelectionOptions: (function() {
      var rows = [];
      for (var i = 0; i < bloodSelectOptions.length; i++) {
        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(
          <MenuItem value={bloodSelectOptions[i].value} key={i}>
            {" "}
            {bloodSelectOptions[i].name}
          </MenuItem>
        );
      }
      return rows;
    })(),
    bloodSelection: state.pwReducer[ownProps.fieldIdentifier || "selectedBlood"]
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(BloodGroup));
