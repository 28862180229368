import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateInvoiceStep1 from "./WizardSteps/CreateInvoiceStep1.jsx";
import CreateInvoiceStep2 from "./WizardSteps/CreateInvoiceStep2.jsx";
import CreateInvoiceStep3 from "./WizardSteps/CreateInvoiceStep3.jsx";
import CreateInvoiceStep4 from "./WizardSteps/CreateInvoiceStep4.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
//icon import
import Customer from "@material-ui/icons/SupervisedUserCircle";
import Product from "@material-ui/icons/LocalMall";
import Discounts from "@material-ui/icons/LocalOffer";
import final from "@material-ui/icons/VerifiedUser";

import Button from "components/CustomButtons/Button.jsx";

class CreateCustomer extends React.Component {
  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <NavPills
            color="rose"
            alignCenter
            tabs={[
              {
                tabButton: "Invoice Details",
                tabIcon: Customer,
                tabContent: (
                  <div>
                    <h4>Customer Details</h4>
                    <CreateInvoiceStep1 />
                    <h4>Products</h4>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={4} lg={5}>
                        <Button color="info">Add New Product</Button>
                      </GridItem>
                    </GridContainer>
                    <Card>
                      <CardBody>
                        <CreateInvoiceStep2 />
                      </CardBody>
                    </Card>
                    <h4>Services</h4>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={4} lg={5}>
                        <Button color="info">Add New Service</Button>
                      </GridItem>
                    </GridContainer>
                    <Card>
                      <CardBody>
                        <CreateInvoiceStep3 />
                      </CardBody>
                    </Card>
                    <h4>Discounts</h4>
                    <GridContainer >
          <GridItem xs={6} sm={6} md={4} lg={5}>
            <Button color="info">Add New Discount</Button>
          </GridItem>
        </GridContainer>
                    <Card>
                      <CardBody>
                        
                        <CreateInvoiceStep4 />
                      </CardBody>
                    </Card>
                    <br></br>
                    <GridContainer justify="center">
                    <GridItem>
            <Button color="rose">Create Invoice</Button>
          </GridItem>
        </GridContainer>
                  </div>
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default CreateCustomer;
