import * as actions from "actions/projectActionTypes";
import axios from "axios";
import { APIURL } from "variables/project";
import { changeUrl } from "variables/coreWeb.jsx";
import Auth from "Auth/Auth";
const auth = new Auth();

export default function genericHttpHandler(res) {
  console.log(res);
  if (res.status == 401) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    auth.handleAuthentication(true);
  }
  return res.data;
}
