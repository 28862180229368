import React from "react";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Pets from "@material-ui/icons/Pets";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CoreSelect from "components/CoreWeb/CoreSelect";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class CreateInvoiceStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: ""
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Card>
          <CardBody>
            <h4>Customers Name</h4>
            <ReduxCustomInput
              subTextLabel="(required)"
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"customerName"}
              textValue={this.props.customerName}
              multiLine={1}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>Customers Address</h4>
            <CoreSelect placeholder="Customer's address" />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(style)(CreateInvoiceStep1);
