import React from "react";
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from 'actions/projectActionTypes';

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import SelectIsActive from "components/CoreWeb/SelectIsActive";






const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class CreateServiceStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {
    
    const { classes } = this.props;
    return (
      <div>   
        

        <GridContainer justify="center">
      
          

        <GridItem xs={12} sm={10} md={10} lg={10}>
          
        </GridItem>

        </GridContainer>
        <GridContainer>



        
        <Card></Card>
        
        <GridItem xs={12} sm={12} md={12} lg={12}>
              <h4><strong>Staff Members</strong></h4>
        </GridItem>
              
        
        <GridItem xs={12} sm={12} md={12} lg={12}>
              <h4><strong>Resources</strong></h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          
        </GridItem>
        <GridItem xs={12} sm={12} md={10} lg={10}>
         
            
          </GridItem>
      </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(CreateServiceStep2);
