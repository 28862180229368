import React from "react";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AnimalPictureUpload from "components/CustomUpload/AnimalPictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ReferralSources from "components/CoreWeb/ReferralSources";

import { hardCodedOrgId } from "variables/project.jsx";
import * as makeApiCall from 'actions/makeApiCall';
import Button from "components/CustomButtons/Button.jsx";
import * as actions from 'actions/projectActionTypes';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class CreateCustomerStep4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: ""
    };
  }
  createCustomer= function(callMe,theProps)
  {
    callMe.req(
      localStorage.getItem('access_token'),
       "Create_Org_Contact", 
       theProps, 
       actions.cw.API_CUSTOMER_CREATE);
  }
  
  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return true;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return true;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        //if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        //} else {
        //  this.setState({ [stateName + "State"]: "error" });
        //}
        break;
      case "length":
        //if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        //} else {
         // this.setState({ [stateName + "State"]: "error" });
        //}
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (
      this.state.firstnameState === "success" &&
      this.state.lastnameState === "success" &&
      this.state.emailState === "success"
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== "success") {
        this.setState({ firstnameState: "error" });
      }
      if (this.state.lastnameState !== "success") {
        this.setState({ lastnameState: "error" });
      }
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }
    }
    return true;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
      
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactAcquisition: 2,
    contactFirstName: state.pwReducer.firstName,
    contactLastName: state.pwReducer.lastName,
    contactTitle: 2,
    contactType: 3,
    emailAddress: state.pwReducer.email,
    orgId: hardCodedOrgId,
    orgName: state.pwReducer.businessName,
    userId: state.pwReducer.selfProfile.userId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(style)(CreateCustomerStep4));
