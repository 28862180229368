import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import MonetaryCurrency from "components/CoreWeb/MonetaryCurrency";
import CoreSelect from "../../../components/CoreWeb/CoreSelect";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class CreateInventoryStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {

    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Add inventory to product stock levels</h4>
          </GridItem>

          <GridItem xs={6} sm={5} md={10} lg={10}>
            <CoreSelect
              placeholder="Select Product"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true
              }}
            />
          </GridItem>


        </GridContainer>
        <GridContainer justify="center">

          <GridItem xs={6} sm={5} md={10} lg={10}>
            <CoreSelect
              placeholder="Arrived or On Order"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true
              }}
            />
          </GridItem>


        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={6} sm={5} md={10} lg={10}>
            <CustomInput
              labelText="Enter Quantity"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true
              }}
            />
          </GridItem>


        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={6} sm={5} md={5} lg={5}>
            <CoreSelect
              placeholder="Select Unit Purchase Cost"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={5} md={5} lg={5}>
            <CoreSelect
              placeholder="Select Currency"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true
              }}
            />
          </GridItem>

        </GridContainer>

        <GridContainer justify="center">

          <GridItem xs={6} sm={5} md={10} lg={10}>
            <CoreSelect
              placeholder="Select Specific Customer"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true
              }}
            />
          </GridItem>

        </GridContainer>
        <GridContainer justify="center">

          <GridItem xs={12} sm={12} md={10} lg={10}>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "Select Arrival Deadline" }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={3} sm={5}>
            <h4></h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">

          <GridItem xs={12} sm={12} md={10} lg={10}>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "Select Estimated Delivery Date" }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={3} sm={5}>
            <h4></h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">

          <GridItem xs={12} sm={12} md={10} lg={10}>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "Select Follow Up Reminder Date" }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={3} sm={5}>
            <h4></h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={6} sm={5} md={10} lg={10}>
            <CustomInput
              labelText="Further Notes"
              id="disabled"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                multiline: true,
                rows: 5
              }}
            />
          </GridItem>


        </GridContainer>

      </div>
    );
  }
}

export default withStyles(style)(CreateInventoryStep1);
