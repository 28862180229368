import * as actions from 'actions/projectActionTypes';

export const createActionGeneric = function(payload,action)
{
  console.log("raising:" + action);
  return { type: action, payload }
}




