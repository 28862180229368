import React from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import auth0 from 'auth0-js';
import withStyles from "@material-ui/core/styles/withStyles";
import YouTube from 'react-youtube';
import FeedItem from "../FeedItem";
import { AUTH_CONFIG } from 'Auth/auth0-variables';
import { changeUrl } from "variables/coreWeb.jsx";

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from 'actions/projectActionTypes';
import * as raisers from 'actions/projectRaisers';

import * as makeApiCall from 'actions/makeApiCall';
import Auth from "Auth/Auth";

const auth = new auth0.WebAuth({
  audience: AUTH_CONFIG.audience,
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  redirectUri: AUTH_CONFIG.callbackUrl,
  responseType: 'token id_token',
  scope: 'openid'
});

const feedStyle = {
    
}
class Logout extends React.Component {
  
    constructor(props)
    {
        super(props);
    }

    componentWillMount() 
    { 
        // HERE WE ARE TRIGGERING THE ACTION
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        auth.logout();
        changeUrl('/dashboard')
    }

    render()
    {
        const { classes } = this.props;
        return (
            <div></div>
          )
    }
    
}



  function mapStateToProps(state) {
    return {
      
    };
  }
  
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      makeApiCall: bindActionCreators(makeApiCall, dispatch)
    };
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(withStyles(feedStyle)(Logout));