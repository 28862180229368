import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import InvoiceDetails from "components/CoreWeb/InvoiceDetails";
import {orderStatusFromType} from "variables/coreWeb.jsx"
import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import ReactTable from "react-table";

import Check from "@material-ui/icons/Check";
import EventBusy from "@material-ui/icons/EventBusy";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { Grid } from "@material-ui/core";
import CoreSelect from "components/CoreWeb/CoreSelect";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle,
  ...userProfileStyles
};

const discountSelect = function()
{
  return (<CoreSelect placeholder="Select Discount"></CoreSelect>);
}

class CreateInvoiceStep3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {
    
    const { classes } = this.props;
    const fillButtons = [
      { color: "danger", icon: Close },
    ].map((prop, key) => {
      return (
        <Button color={prop.color} className={classes.actionButton} key={key}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
    return (
      <div>

      <GridContainer justify="center">
      <GridItem xs={6} sm={6} md={12} lg={12}>
          <ReactTable
                data={dataTable5.dataRows.map((prop, key) => {
                  return {
                    id: key,
                    name: prop[0],
                    position: labelFromType(prop[1]),
                    status: orderStatusFromType(prop[2]),
                    age: prop[3],
                    actions: fillButtons,
                    workingFor: prop[2] != 1 ? '????' : "Coca Cola",
                    totalValue: "100$",
                    productOptions: discountSelect(),
                    quantitySelect: discountSelect()
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Discount",
                    accessor: "productOptions",
                    sortable: false,
                    filterable: false
                  },
                  
                
                  {
                    Header: "Discount value",
                    accessor: "totalValue",
                    sortable: false,
                    filterable: false
                  },     
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={3}
                showPaginationTop={false}

                showPaginationBottom={false}
                className="-striped -highlight"
              />
        </GridItem>
        
      </GridContainer>
      <br></br>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <InvoiceDetails></InvoiceDetails>
        </GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(CreateInvoiceStep3);
