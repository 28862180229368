import React from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Chat from "@material-ui/icons/Chat"
import Share from "@material-ui/icons/Share"
import InputLabel from "@material-ui/core/InputLabel";

import withStyles from "@material-ui/core/styles/withStyles";
import YouTube from 'react-youtube';

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";

const feedStyle = {}

function Feed({ ...props }) {
  const { classes } = props;
    return (
      <Card>
      <CardBody>
          <GridContainer>
            <GridItem md={8}>
            <h2>{props.title}</h2>
            <h5>{props.updatedAt}</h5>
            </GridItem>
            <GridItem md={4}>
              <Button size="sm">
                <MoreHoriz></MoreHoriz>
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
            <p>
            {props.textDetail}
          </p>
            </GridItem>
          </GridContainer>
          <br></br>
          {/*<GridContainer>
            <GridItem></GridItem>
            <GridItem>
            <YouTube videoId={props.youtubeVideoUrl}/>
            </GridItem>
            <GridItem></GridItem>
          </GridContainer>*/}
          <GridContainer>
            <GridItem></GridItem>
            <GridItem>
            <img src={props.imageUrl}/>
            </GridItem>
            <GridItem></GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem></GridItem>
            <GridItem md={2}>
              <Button round size="sm">
                <ThumbUp></ThumbUp>
                {props.likeCount}
              </Button>
            </GridItem>
            <GridItem md={8}>
            <Button round size="sm">
                <Chat></Chat>
                {props.commentCount}
              </Button>
              <Button round size="sm">
                <Share></Share>
                {props.shareCount}
              </Button>
            </GridItem>
          </GridContainer>
          <Clearfix />
          <GridContainer>
          <GridItem md={12}>
                <div className={classes.buttonGroup}>
                  <Button color="info" className={classes.firstButton}>
                  <ThumbUp></ThumbUp>
                    Like
                    
                  </Button>
                  <Button color="info" className={classes.middleButton}>
                  <Chat></Chat>
                     Comment
                  </Button>
                  <Button color="info" className={classes.lastButton}>
                  <Share></Share>
                    Share
                  </Button>
                </div>
            </GridItem>
          </GridContainer>
          <Clearfix />
          <br></br>
          <br></br>
          <GridContainer>
            <GridItem md={1}>
              <img></img>
            </GridItem>
            <GridItem md={11}>
            <InputLabel style={{ color: "#AAAAAA" }}>Add comment</InputLabel>
                  <CustomInput
                    
                    labelText=""
                    id="add-comment"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5
                    }}
                  />
            </GridItem>
          </GridContainer>
      </CardBody>
    </Card>
    )
}

Feed.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default withStyles(feedStyle)(Feed);