import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { hardCodedOrgId } from "variables/project.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import * as actions from "actions/projectActionTypes";
import * as makeApiCall from "actions/makeApiCall";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// core components
import NavPills from "components/NavPills/NavPills.jsx";

//icon import
import Contact_mail from "@material-ui/icons/ContactMail";
import Phone_social from "@material-ui/icons/PermDeviceInformation";
import LocationOn from "@material-ui/icons/LocationOn";
import final from "@material-ui/icons/VerifiedUser";
import CreateCustomerStep1 from "views/Forms/WizardSteps/CreateCustomerStep1";
import CreateCustomerStep2 from "views/Forms/WizardSteps/CreateCustomerStep2";
import CreateCustomerStep3 from "views/Forms/WizardSteps/CreateCustomerStep3";
import CreateCustomerStep4 from "views/Forms/WizardSteps/CreateCustomerStep4";
import PrimaryContactOptions from "components/CoreWeb/PrimaryContactOptions";
import SecondaryContactOptions from "components/CoreWeb/SecondaryContactOptions";
import SocialPrimaryOptions from "components/CoreWeb/SocialPrimaryOptions";
import SocialSeondaryOptions from "components/CoreWeb/SocialSeondaryOptions";
import ContactBasicOptions from "components/CoreWeb/ContactBasicOptions";
import ContactImage from "components/CoreWeb/ContactImage";
import Button from "components/CustomButtons/Button.jsx";
import AddressList from "components/CoreWeb/AddressList";
import LocationCity from "@material-ui/icons/LocationCity";
import Pets from "@material-ui/icons/Pets";
import Assignment from "@material-ui/icons/Assignment";
import { getMostRecentFileFromList } from "variables/coreWeb.jsx";
import { UPLOADSPATH } from "variables/project.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class ContactViewOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDelete: 0,
      isButtonClick: false,
      selectCustomerData: {}
    };
  }

  getContactTabs = function(theProps) {
    var resp = [];
    resp.push({
                tabButton: "Details",
                tabIcon: Contact_mail,
                tabContent: (
                  <div>
          {theProps.contactId ? (
                      <div>
                        <h4>Fan Image</h4>
                        <Card>
                          <CardBody>
                  <ContactImage
                    contactId={theProps.contactId}
                    orgId={hardCodedOrgId}
                    imagePreviewUrl={theProps.activeFileUrl}
                  />
                          </CardBody>
                        </Card>
                      </div>
                    ) : (
                      ""
                    )}
                    <h4>Fan Details</h4>
          <ContactBasicOptions disabled={theProps.disabled} />
                    {/*<h4>Phone Numbers</h4>
                    <Card>
                      <CardBody>
                        <PrimaryContactOptions />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <SecondaryContactOptions />
                      </CardBody>
                    </Card>
                    <h4>Social Media</h4>
                    <Card>
                      <CardBody>
                        <SocialPrimaryOptions />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <SocialSeondaryOptions />
                      </CardBody>
                    </Card>*/}
                  </div>
                )
    });
    /*if (theProps.contactId != undefined) {
      resp.push({
                tabButton: "Addresses",
                tabIcon: LocationCity,
                tabContent: (
                  <div>
            <AddressList
              contactId={theProps.contactId}
              contactDetails={theProps.contactDetails}
            />
                  </div>
                )
      });
    }*/
    if (theProps.contactId != undefined && theProps.contactType == 2) {
      resp.push({
        tabButton: "Patients",
        tabIcon: Pets,
        tabContent: (
          <div>
            <Card>
              <CardBody>
                <h4>Coming Soon</h4>
              </CardBody>
            </Card>
          </div>
        )
      });
    }
    if (theProps.contactId != undefined) {
      resp.push({
        tabButton: "Loyalty",
        tabIcon: Assignment,
        tabContent: (
          <div>
            <Card>
              <CardBody>
                <h4>Coming Soon</h4>
              </CardBody>
            </Card>
          </div>
        )
      });
    }
    return resp;
  };
  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <NavPills
            color="rose"
            alignCenter
            tabs={this.getContactTabs(this.props)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  var activeFile = null;
  if (
    state.pwReducer.contactDetails &&
    state.pwReducer.contactDetails.fileList &&
    state.pwReducer.contactDetails.fileList.length > 0
  ) {
    activeFile = getMostRecentFileFromList(
      state.pwReducer.contactDetails.fileList
    );
  }
  return {
    contactDetails: state.pwReducer.contactDetails,
    activeFileUrl:
      activeFile != null
        ? UPLOADSPATH +
          activeFile.fileId +
          "" +
          activeFile.fileExtension
        : null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ContactViewOptions));
