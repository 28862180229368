import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import CardAvatar from "components/Card/CardAvatar.jsx";
import { useHistory } from "react-router-dom";
import { hardCodedOrgId } from "variables/project.jsx";
import { paths } from "variables/projPaths.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import * as makeApiCall from "actions/makeApiCall";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import defaultImage from "assets/img/default-avatar.png";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import * as raisers from "actions/projectRaisers";
import { deleteContactWithDispatch } from "../../variables/thunks";
import CoreTitleCard from "./CoreTitleCard";
import { getActiveOrg } from "../../variables/coreWeb";
const style = {
  ...userProfileStyles,
  ...sweetAlertStyle,
};

const customerImage = function() {
  return (
    <CardAvatar cardAvatarProfileNormal>
      <a href="#pablo" onClick={(e) => e.preventDefault()}>
        <img
          src={defaultImage}
          alt="..."
          style={{
            height: "50px",
            width: "50px",
          }}
        />
      </a>
    </CardAvatar>
  );
};

// const deleteContact = function(callMe, contactId) {
//   var payLoad = {};
//   payLoad.contactId = contactId;
//   payLoad.orgId = hardCodedOrgId;

//   callMe.req(
//     localStorage.getItem("access_token"),
//     "Delete_Contact",
//     payLoad,
//     actions.cw.API_CONTACT_DELETE
//   );
// };

const CustomerList = function(props) {
  const { classes } = props;
  const history = useHistory();
  const loadCustomerView = function(customerId) {
    history.push(paths.customerView + "/" + customerId);
  };
  const fillButtons = function(theProps, customerId) {
    return (
      <div>
        <Button
          className={classes.actionButton}
          color="info"
          size="sm"
          onClick={() => {
            loadCustomerView(customerId);
          }}
        >
          <Visibility className={classes.icon} />
        </Button>
        <Button
          className={classes.actionButton}
          color="danger"
          size="sm"
          onClick={() => {
            theProps.raiseAction({
              fieldIdentifier: "selectedCustomerId",
              value: customerId,
            });
          }}
        >
          <Clear className={classes.icon} />
        </Button>
      </div>
    );
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>Your Brands</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CoreTitleCard
            color="rose"
            title={"Total Brands"}
            subtitle={"Your total rankFans brands"}
            value={10}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CoreTitleCard
            color="warning"
            title={"Highest FanRank"}
            subtitle={"Your highest ranking brand following"}
            value={10}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CoreTitleCard
            color="info"
            title={"Rank Direction"}
            subtitle={"Your averafe rank direction last month"}
            value={10}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <CoreApiReq
          endpointToCall={"Read_All_Organisation_Contacts"}
          afterActionHandler={actions.cw.GOT_ALL_ORG_CONTACTS_BY_ORG_ID}
          data={{
            orgId: getActiveOrg(),
          }}
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4 className={classes.cardIconTitle}>
            Your Fans
            <small> - Existing fans of your brands</small>
          </h4>
          <Card>
            <CardBody>
              <ReactTable
                data={props.contactList.map((prop, key) => {
                  return {
                    id: key,
                    customerData: prop,
                    name: prop.contactFullName,
                    image: customerImage(),
                    email: prop.emailAddress,
                    actions: fillButtons(props, prop.contactId),
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Fan Image",
                    accessor: "image",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Fan Name",
                    accessor: "name",
                    sortable: false,
                    filterable: false,
                  },

                  {
                    Header: "Email Address",
                    accessor: "email",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                // get selectedIndexIndex row data
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: () => {
                        console.log(rowInfo);
                      },
                    };
                  } else {
                    return {};
                  }
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        {props.selectedCustomerId ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title="Are you sure?"
            onConfirm={() => {
              alert("Coming soon");
            }}
            onCancel={() => {}}
            confirmBtnCssClass={
              props.classes.button + " " + props.classes.success
            }
            cancelBtnCssClass={
              props.classes.button + " " + props.classes.danger
            }
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Deleting a customer is permanent
          </SweetAlert>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    contactList: (state.pwReducer.contactList || []).filter(
      (x) => x.contactType == 3
    ),
    selectedCustomerId: state.pwReducer.selectedCustomerId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch,
    raiseAction: (payload) => {
      dispatch(raisers.createActionGeneric(payload, actions.cw.SET_STATE_VAL));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CustomerList));
