import React from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import YouTube from 'react-youtube';
import FeedItem from "../FeedItem";

import * as serviceOrdersSelf from 'actions/serviceOrdersSelf';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const feedStyle = {
    
}
class ReadServiceOrdersSelf extends React.Component {
    
    constructor(props)
    {
        super(props);
    }

    componentWillMount() 
    { 
        // HERE WE ARE TRIGGERING THE ACTION
        this.props.serviceOrdersSelf.req();
    }

    render()
    {
        const { classes } = this.props;
        return (
            <div></div>
          )
    }
    
}

ReadServiceOrdersSelf.propTypes = {
    selfProfileAction: PropTypes.object,
    selfProfile: PropTypes.array
  };

  function mapStateToProps(state) {
    return {
      selfProfile: state.selfProfile
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      serviceOrdersSelf: bindActionCreators(serviceOrdersSelf, dispatch)
    };
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(withStyles(feedStyle)(ReadServiceOrdersSelf));