import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from 'react-star-rating-component';
import Visibility from "@material-ui/icons/Visibility";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import CardAvatar from "components/Card/CardAvatar.jsx";

import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import { dataTable5, statusFromType } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";
import * as actions from 'actions/projectActionTypes';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...userProfileStyles
};

const agreeDisagreeOpts = [
  {
    name:'Disagree',
    value: 2
  },
  {
    name:"Agree",
    value: 3
  }
]


class SelectAgreeDisagree extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <ReduxCoreSelect 
      placeholder="Agree or Disagree" 
      options={this.props.agreeDisagreOptions}  
      fieldIdentifier={this.props.fieldIdentifier || "selectAgreeDisagree"}
      updateAction={actions.cw.UPREDUXSELECTINPUT}
      inputProps={{value:this.props.campaignAgreeDisagree}}
      ></ReduxCoreSelect>
    );
  }
}


function mapStateToProps(state, ownProps) {
  return {
    agreeDisagreOptions: function(){
      var rows = [];
        for (var i = 0; i < agreeDisagreeOpts.length; i++) {
        // note: we add a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(<MenuItem
          value={agreeDisagreeOpts[i].value}
          key={i}
        >
          {agreeDisagreeOpts[i].name}
        </MenuItem>);
        }
        return rows;
      }(),
      campaignAgreeDisagree: state.pwReducer[ownProps.fieldIdentifier || "selectAgreeDisagree"]
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(userProfileStyles)(SelectAgreeDisagree));











