import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import CoreSelect from "components/CoreWeb/CoreSelect";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { hardCodedOrgId } from "variables/project.jsx";

import * as makeApiCall from "actions/makeApiCall";
import { bindActionCreators } from "redux";
import Button from "components/CustomButtons/Button.jsx";

import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import { createResources } from "../../../variables/coreCreateRequests";
import { updateResource } from "../../../variables/coreRequests";
import { updateResourceWithDispatch } from "../../../variables/thunks";
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class CreateResourceStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.resourceId != undefined ? (
          <CoreApiReq
            endpointToCall={"Read_Resource"}
            afterActionHandler={actions.cw.GOT_RESOURCE_DETAILS}
            data={{
              resourceId: this.props.resourceId,
              orgId: hardCodedOrgId
            }}
          />
        ) : (
          ""
        )}
        <Card>
          <CardBody>
            <h4>Resource Name</h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"resourceDetails.resourceName"}
              multiLine={1}
            />
          </CardBody>
        </Card>

            <GridContainer justify="center">
            <GridItem>
        {this.props.resourceId == undefined ? (
          
              <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={() => {
                  createResources(this.props.callApi, this.props);
                }}
              >
                Create Resource
              </Button>
           
        ) : (
          <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={() => {
                   this.props.dispatch(updateResourceWithDispatch(this.props.callApi, this.props.resourceDetails));
                }}
              >
                Update Resource
              </Button>
        )}
         </GridItem>
          </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resourceDetails: state.pwReducer.resourceDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch),
    dispatch: dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateResourceStep1));
