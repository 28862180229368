export const READ_SELFPROFILE = "READ_SELFPROFILE";
export const GOT_SELFPROFILE = "GOT_SELFPROFILE";
export const GOT_ORGLIST = "GOT_ORGLIST";
export const READ_SERVICEORDERS_SELF = "READ_SERVICEORDERS_SELF";
export const GOT_SERVICEORDERS_SELF = "RECEIVE_SERVICEORDERS_SELF";
export const GOT_SERVICEFULFILMENTS_SELF = "GOT_SERVICEFULFILMENTS_SELF";
export const GOT_ORGDETAILS = "GOT_ORGDETAILS";
export const UPREDUXCUSTOMINPUT = "UPREDUXCUSTOMINPUT";
export const UPREDUXSELECTINPUT = "UPREDUXSELECTINPUT";
export const UPREDUXCOREICONINPUT = "UPREDUXCOREICONINPUT";
export const UPREDUXCOREICONINPUTSINGLE = "UPREDUXCOREICONINPUTSINGLE";
export const UPREDUXGROUPBUTTONSELECTINPUT = "UPREDUXGROUPBUTTONSELECTINPUT";
export const GOT_AVAILABLESTARTTIMES = "GOT_AVAILABLESTARTTIME";
export const GOT_ALLORGSERVICEORDERS = "GOT_ALLORGSERVICEORDERS";
export const GOT_SERVICEORDERS_ORG = "GOT_SERVICEORDERS_ORG";
export const API_RESP_CREATE = "API_RESP_CREATE";
export const API_RESOURCE_CREATE = "API_RESOURCE_CREATE";
export const API_ANIMAL_CREATE = "API_ANIMAL_CREATE";
export const API_CUSTOMER_CREATE = "API_CUSTOMER_CREATE";
export const API_PRODUCT_CREATE = "API_PRODUCT_CREATE";
export const API_SERVICE_CREATE = "API_SERVICE_CREATE";
export const API_MEMBER_CREATE = "API_MEMBER_CREATE";
export const API_ADDRESS_CREATE = "API_ADDRESS_CREATE";
export const API_MEDICAL_CREATE = "API_MEDICAL_CREATE";
export const GOT_SERVICEFULFILMENTCONFIG = "GOT_SERVICEFULFILMENTCONFIG";
export const GOT_ANIMAL_MEDICAL_RECORD = "GOT_ANIMAL_MEDICAL_RECORD";
export const GOT_ALL_ORG_ANIMALS_BY_ORG_ID = "GOT_ALL_ORG_ANIMALS_BY_ORG_ID";
export const GOT_ALL_ORG_CONTACTS_BY_ORG_ID = "GOT_ALL_ORG_CONTACTS_BY_ORG_ID";
export const GOT_ALL_ORG_PRODUCTS = "GOT_ALL_ORG_PRODUCTS";
export const GOT_ALL_ORG_RESOURCES_BY_ORG_ID = "GOT_ALL_ORG_RESOURCES_BY_ORG_ID";
export const GOT_ALL_SERVICES_BY_ORG_ID = "GOT_ALL_SERVICES_BY_ORG_ID";
export const GOT_ALL_SFCS_BY_ORG_ID = "GOT_ALL_SFCS_BY_ORG_ID";
export const GOT_ALL_ORG_USERS = "GOT_ALL_ORG_USERS";
export const API_CONTACT_DELETE = "API_CONTACT_DELETE";
export const API_CONTACT_ADDRESS_DELETE = "API_CONTACT_ADDRESS_DELETE";
export const API_ANIMAL_DELETE = "API_ANIMAL_DELETE"
export const API_SERVICE_DELETE = "API_SERVICE_DELETE";
export const API_PRODUCT_DELETE = "API_PRODUCT_DELETE"
export const API_MEDICAL_DELETE = "API_MEDICAL_DELETE"
export const API_RESOURCE_DELETE = "API_RESOURCE_DELETE"
export const GOT_ORGDETAILSLIMITED = "GOT_ORGDETAILSLIMITED";
export const NOTI_CREATESUCCESS = "NOTI_CREATESUCCESS";
export const NOTI_CREATESUCCESS_HIDE = "NOTI_CREATESUCCESS_HIDE";
export const SET_STATE_VAL = "SET_STATE_VAL";
export const GOT_ANIMAL_DETAILS = "GOT_ANIMAL_DETAILS";
export const GOT_RESOURCE_DETAILS = "GOT_RESOURCE_DETAILS";
export const GOT_CONTACT_DETAILS = "GOT_CONTACT_DETAILS";
export const GOT_SERVICE_DETAILS = "GOT_SERVICE_DETAILS";
export const GOT_PRODUCT_DETAILS = "GOT_PRODUCT_DETAILS";
export const GOT_ALL_ORG_INVOICES_BY_ORG_ID = "GOT_ALL_ORG_INVOICES_BY_ORG_ID";
export const API_CUSTOMER_UPDATE = "API_CUSTOMER_UPDATE";
export const API_ANIMAL_UPDATE = "API_ANIMAL_UPDATE";
export const API_SERVICE_UPDATE = "API_SERVICE_UPDATE";
export const API_PRODUCT_UPDATE = "API_PRODUCT_UPDATE";
export const API_RESOURCE_UPDATE = "API_RESOURCE_UPDATE";
export const MANDSTAFFSELECTCHANGE = "MANDSTAFFSELECTCHANGE";
export const GOT_ALL_ORG_RESOURCES = "GOT_ALL_ORG_RESOURCES";
export const GOT_SERVICEFULFILMENT_COMPLETE = "GOT_SERVICEFULFILMENT_COMPLETE";
export const PAGE_RELOAD = "PAGE_RELOAD";
export const READ_DASHBOARD = "READ_DASHBOARD";


