import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import configureStore from "store/configureStore";
import { AUTH_CONFIG } from "Auth/auth0-variables";
import { Auth0Provider } from "@auth0/auth0-react";
import { CALLBACKURL } from "variables/project";

import indexRoutes from "routes/index.jsx";
import Dashboard from "layouts/Dashboard";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

function getClientId() {
  /*console.log('environmet: ', process.env.NODE_ENV);
    switch (process.env.NODE_ENV) {
      case 'production':
        return constVars.AUTH_CLIENT_ID_PRODUCTION;
  
      case 'internal-prod':
        return constVars.AUTH_CLIENT_ID_INTERNAL_PROD;
  
      case 'preprod':
        return constVars.AUTH_CLIENT_ID_PREPROD;
  
      case 'verify':
        return constVars.AUTH_CLIENT_ID_VERIFY;
  
      case 'test':
        return constVars.AUTH_CLIENT_ID_TEST;
  
      case 'development':
        return constVars.AUTH_CLIENT_ID_TEST;
  
      default:
        throw new Error('Invalid environment variable is being used!');
    }*/
  return AUTH_CONFIG.clientId;
}

const hist = createBrowserHistory();
const store = configureStore();

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH_CONFIG.domain}
      clientId={getClientId()}
      redirectUri={CALLBACKURL}
      audience={AUTH_CONFIG.audience}
      domain={AUTH_CONFIG.domain}
    >
      {children}
    </Auth0Provider>
  );
};

ReactDOM.render(
  <Auth0ProviderWithHistory>
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          {/* {indexRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })} */}

          <Route path="/app/*" component={Dashboard} />
          <Redirect from="/*" to="/app/dashboard" />
        </Switch>
      </Router>
    </Provider>
  </Auth0ProviderWithHistory>,
  document.getElementById("root")
);
