import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";



import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import NumberRangeSelect from "components/CoreWeb/NumberRangeSelect";


class PrePaymentRequiredSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    
    return (
      <NumberRangeSelect
      placeholder="Select Number Of Optional Resources"
      start={this.props.start == undefined ? 0 : this.props.start}
      stop={this.props.stop == undefined ? 5 : this.props.stop}
      useValueNotIndex={true}
      fieldIdentifier={"optionalResources"}
      disabled={this.props.disabled}
    />
    );
  }
}

export default withStyles(userProfileStyles)(PrePaymentRequiredSelect);











