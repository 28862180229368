import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ReduxCoreSelect from "components/CoreWeb/ReduxCoreSelect";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LocationCity from "@material-ui/icons/LocationCity";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddressView from "./AddressView";

class AddressList extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      shouldShowCreateDialog:false
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4>Addresses</h4>
        <Button color="info" onClick={() => {this.setState({shouldShowCreateDialog: true})}}>Create New Address</Button>

        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <LocationCity />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Address List
              <small> - The addresses which are configured</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={[]}
              columns={[
                {
                  Header: "Patient Image",
                  accessor: "img"
                },
                {
                  Header: "Patient Name",
                  accessor: "name"
                },
                {
                  Header: "Patient Type",
                  accessor: "type"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
            />
          </CardBody>
        </Card>
        {this.state.shouldShowCreateDialog ? (
          <Dialog
            open={true}
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            keepMounted
            onClose={() => this.handleClose()}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle} />
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <AddressView />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={() => {this.setState({shouldShowCreateDialog: false})}} color="simple">
                Close
              </Button>
              <Button
                onClick={() => this.handleCreate(this.state)}
                color="success"
              >
                Create Address
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(AddressList));
