import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import DeSexedStatus from "components/CoreWeb/DeSexedStatus";
import BloodGroup from "components/CoreWeb/BloodGroup";
import MonetaryCurrency from "components/CoreWeb/MonetaryCurrency";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import { hardCodedOrgId } from "variables/project.jsx";
import * as makeApiCall from "actions/makeApiCall";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "actions/projectActionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class CreateStaffMemberStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.match &&
        this.props.match.params &&
        this.props.match.params.contactId != undefined ? (
          <CoreApiReq
            endpointToCall={"ReadContactCompleteByContactID"}
            afterActionHandler={actions.cw.GOT_SERVICEORDERS_SELF}
            data={{
              contactId: this.props.match.params.contactId,
              orgId: this.props.orgId
            }}
          />
        ) : (
          ""
        )}
        <h4>Staff Image</h4>
        <Card>
          <CardBody>
            <PictureUpload />
          </CardBody>
        </Card>
        <h4>Staff Details</h4>
        <Card>
          <CardBody>
            <h4>First Name</h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"staffFirstName"}
              multiLine={1}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>Last Name</h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"staffLastName"}
              multiLine={1}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>Email Address</h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"staffEmailAddress"}
              multiLine={1}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactFirstName: state.pwReducer.staffFirstName,
    contactLastName: state.pwReducer.staffLastName,
    emailAddress: state.pwReducer.staffEmailAddress,
    orgId: hardCodedOrgId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    callApi: bindActionCreators(makeApiCall, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CreateStaffMemberStep1));
