import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { useHistory } from "react-router-dom";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";
import configureStore from 'store/configureStore';
import { connect } from 'react-redux'
import { Provider } from 'react-redux'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useLocation } from "react-router-dom";
import ReduxNotificationHeaderGroup from "components/CoreWeb/ReduxNotificationHeaderGroup";
import genericHttpHandler from "actions/genericHttpHandler";
import {
  getActiveOrg,
  getISODateTime,
  calcDurationDiff,
  resetActiveOrg,
} from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import {
  apiGetDashboard,
  apiGetSelfProfile,
  getToken,
} from "../variables/newApi";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";








var ps;

const Dashboard = function(props) {
  const [mobileView, setMobileView] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [open, setOpen] = useState(false);
  const refMainPanel = useRef();
  const [activeOrg, setActiveOrg] = useState(null);
  const history = useHistory();

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const { classes, filteredRoutes, ...rest } = props;

  const sidebarMinimize = () => {
    this.setMiniActive(!miniActive);
  };

  const getRoute = function() {
    return props.location.pathname !== "/maps/full-screen-maps";
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleDrawerToggle = () => {
    setMiniActive(!miniActive);
  };

  useEffect(() => {
    resizeFunction();
  });

  const registrationType = useSelector(
      (state) => state.pwReducer.selfProfile.registrationType
  );

  const location = useLocation();

  const resizeFunction = () => {
    console.log("InnerWidth" + window.innerWidth);
    if (window.innerWidth >= 960) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  };

  const checkForActiveOrg = function() {
    const org = getActiveOrg();

    if (org) {
      setActiveOrg(org);
    } else {
      if (
          location.pathname != paths.orgSignIn &&
          !location.pathname.includes(paths.createOrganisation)
      ) {
        history.push(paths.orgSignIn);
      }
    }
  };

  const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
      });

  const switchRoutes = (
    <Switch>
      {filteredRoutes.map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        if (prop.collapse)
          return prop.views.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={withAuthenticationRequired(prop.component, {
                  onRedirecting: () => <div>Loading...</div>,
                })}
                key={key}
              />
            );
          });
        return (
          <Route
            path={prop.path}
            component={withAuthenticationRequired(prop.component, {
              onRedirecting: () => <div>Loading...</div>,
            })}
            key={key}
          />
        );
      })}
    </Switch>
  );
    return (

        <div className={classes.wrapper}>
          <ReduxNotificationHeaderGroup></ReduxNotificationHeaderGroup>
          <Sidebar
            routes={filteredRoutes}
            logoText={"RankFans"}
            logo={logo}
            handleDrawerToggle={handleDrawerToggle}
            open={miniActive}
            color="rose"
            bgColor="black"
            miniActive={miniActive}
            {...rest}
          />

          <div className={mainPanel} ref={refMainPanel}>
            <Header
              sidebarMinimize={sidebarMinimize}
              miniActive={miniActive}
              routes={filteredRoutes}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
            <div className={classes.paddedInline}>

            </div>
            {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
            {getRoute() ? <Footer fluid /> : null}
          </div>
        </div>

    );

}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    filteredRoutes: function () {
      var filterRoutes = dashboardRoutes.filter(prop => {
        return !prop.registrationType || prop.registrationType == state.pwReducer.selfProfile.registrationType || state.pwReducer.selfProfile.registrationType === undefined;
      });

      for (var i = 0; i < filterRoutes.length; i++) {
        if (filterRoutes[i].views) {
          var returnViews = [];
          for (var l = 0; l < filterRoutes[i].views.length; l++) {
            var currentView = filterRoutes[i].views[l];
            if (!currentView.registrationType || currentView.registrationType == state.pwReducer.selfProfile.registrationType || state.pwReducer.selfProfile.registrationType === undefined) {
              returnViews.push(currentView);
            }
            else {
              //its not valid for the user
            }
          }
          filterRoutes[i].views = returnViews;
        }

      }
      return filterRoutes;
    }()
  };
}


function mapDispatchToProps(dispatch) {
  return {

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(Dashboard));

