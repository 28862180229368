import * as actions from 'actions/projectActionTypes';
import axios from 'axios';
import {APIURL} from "variables/project"

function url() {
  return APIURL ;
}

export function gotSelfServiceOrders(json) {
  return {type: actions.cw.GOT_SERVICEORDERS_SELF, payload: json};
}

export function req(apiKey) {
  return dispatch => {
    axios.defaults.headers.common['Authorization'] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlF6WkdSVFZFTTBWQk16TkJOelJEUWtZMlJEZzFPVVF5UTBFMlJUUTNRMFF6TnpORk16QkdRUSJ9.eyJodHRwczovL2FwcC5ncnlkLm9ubGluZS9lbWFpbCI6InRlc3Q0QHRlc3QuY29tIiwiaXNzIjoiaHR0cHM6Ly9vbGRvLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw1YmUzNDdkMDNkNDdlZDYxMTllZjUzODQiLCJhdWQiOlsiZ3J5ZC5vbGl2ZXJkb2xhbi5jb20iLCJodHRwczovL29sZG8uZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTU0NjA5MTg2NywiZXhwIjoxNTQ2MDk5MDY3LCJhenAiOiJ0ZkNTVEZPajA3M1p6dk1mZnZtQUgzWEhqTWRUYUp3ciIsInNjb3BlIjoib3BlbmlkIGVtYWlsIn0.0DJNkBja0jsybZ1-5PP1-zW6Syj7S0v2vcGhAef8yLOBqdqt3GedHjeSb4fTfJjBNJ27SU_aUsDNN5PEcPKFFszHxY9nopQMjeYe1S2GxWfm3MBIP0Ya-uJRCA4CJInBE9eBAMHSB0AoPN7iFfbpk7Uo92yu0Z60qKkU3rmNOnZ7-WBWIwR78SXM9yhspxJaydc8vX9ahwhYZ_BFCcZ7E-tgUBJTGRkoapQZzaoZCKTpeUnspvj0A1SZo8Ms51d1su9upEcTEqOAcFWvcbhjMh7T6rnJYAz9uVEsKD23KnGWkxbjylwyPGNZGn1MVuBhdvFkt95ejZI4MV-L1Unfyg` 
    axios.post(
      APIURL + 'Read_All_Self_Service_Orders_By_User_ID',
    )
    .then(res =>  res.data)
    .then(json => dispatch(gotSelfServiceOrders(json)));
  };
}