import history from "../history";
import auth0 from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";
import { changeUrl } from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";
import { CALLBACKURL } from "variables/project.jsx";

export default class Auth {
  auth0 = new auth0.WebAuth({
    audience: AUTH_CONFIG.audience,
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: "token id_token",
    scope: "openid",
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    this.auth0.authorize({
      prompt: "login",
    });
  }

  handleAuthentication(autoLogin = false, history) {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        //this.setSession(authResult, 3000);
        this.setSession(authResult, function() {
          history ? history.push(paths.dashboard) : changeUrl(paths.dashboard);
        });
        history
          ? history.push(new URL(CALLBACKURL).pathname)
          : changeUrl(new URL(CALLBACKURL).pathname);
      } else if (err) {
        //changeUrl(paths.dashboard);
        history
          ? history.push(new URL(CALLBACKURL).pathname)
          : changeUrl(new URL(CALLBACKURL).pathname);
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
      if (autoLogin) {
        if (!this.isAuthenticated()) {
          this.login();
        } else {
          changeUrl(paths.dashboard);
        }
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    // navigate to the home route
    //changeUrl(paths.dashboard);
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    // navigate to the home route
    //changeUrl(paths.dashboard);
    this.auth0.logout({
      returnTo: CALLBACKURL,
    });
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }
}
