import React from "react";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";
import ReferralSources from "components/CoreWeb/ReferralSources";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GenderSelect from "components/CoreWeb/GenderSelect";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class PricingName extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
            <ReduxCustomInput
                  updateAction={actions.cw.UPREDUXCUSTOMINPUT}
                  fieldIdentifier={this.props.fieldIdentifier || 'pricingName'}
                  multiLine={1}
                  disabled={this.props.disabled}
                  />
      </div>
    );
  }
}

export default withStyles(style)(PricingName);
