import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import StarRatingComponent from "react-star-rating-component";
import Visibility from "@material-ui/icons/Visibility";
import VpnKey from "@material-ui/icons/VpnKey";
import CoreApiReq from "components/CoreWeb/Containers/CoreApiReq.jsx";
import * as actions from "actions/projectActionTypes";
import { useHistory } from "react-router-dom";
import { dataTable4 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getActiveOrg } from "variables/coreWeb";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { changeUrl, viewButton, tableButton } from "variables/coreWeb.jsx";
import { paths } from "variables/projPaths.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const OrganisationList = function(props) {
  const { classes } = props;
  const history = useHistory();

  function handleCreateClick() {
    history.push(paths.orgView);
  }

  const decideButtons = function(prop, classes) {
    return [
      viewButton(function() {
        history.push(paths.orgView + "/" + prop.orgId);
      }, classes),
      prop.orgId != getActiveOrg(() => {})
        ? tableButton(
            function(props) {
              window.localStorage.setItem("currentOrg", props[0].orgId);
              if (!props[1]) {
                history.push(paths.dashboard);
              }
            },
            classes,
            "success",
            VpnKey,
            [prop]
          )
        : tableButton(
            function(props) {
              alert("Already Signed In");
            },
            classes,
            "",
            VpnKey,
            [prop]
          ),
    ];
  };

  return (
    <GridContainer>
      <CoreApiReq
        endpointToCall={"Read_All_User_Organisations"}
        afterActionHandler={actions.cw.GOT_ORGLIST}
        data={{}}
      />
      <GridItem xs={12}>
        <Button color="rose" onClick={handleCreateClick}>
          Create new brand
        </Button>
        <h4 className={classes.cardIconTitle}>
          Your Brands
          <small> - The brands you control and are part of</small>
        </h4>
        <Card>
          <CardBody>
            <ReactTable
              data={props.orgList.map((prop, key) => {
                return {
                  id: prop.orgId,
                  name: prop.orgName,
                  age: viewButton(function() {
                    history.push(paths.viewOrganisation + "/" + prop.orgId);
                  }, classes),
                  actions: decideButtons(prop, classes),
                };
              })}
              filterable
              columns={[
                {
                  Header: "Brand Name",
                  accessor: "name",
                },

                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

function mapStateToProps(state) {
  return {
    orgList: state.pwReducer.orgList,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userProfileStyles)(OrganisationList));
