import React from "react";
import Button from "components/CustomButtons/Button.jsx";

const DEBUG = false;
const POSTLOGINURL = "/dashboard";

const labelFromType = function(opoType) {
  if (opoType == 1) {
    return (
      <Button color="info" size="sm">
        Canine
      </Button>
    );
  }
  if (opoType == 2) {
    return (
      <Button color="info" size="sm">
        Feline
      </Button>
    );
  }

  if (opoType == 3) {
    return (
      <Button color="info" size="sm">
        Bovine
      </Button>
    );
  }
};

const hardCodedOrgId = 4;
const APIURL = DEBUG
  ? "https://localhost:3000/api/"
  : "https://api.rankfans.com/api/";

const CALLBACKURL = DEBUG
  ? "http://localhost:3000/app/dashboard"
  : "https://app.rankfans.com/app/dashboard";

const UPLOADSPATH = DEBUG
  ? "http://localhost:4010//"
  : "http://uploads.vetgeo.com/";

export {
  // data for React Big Calendar in Calendar view
  POSTLOGINURL,
  labelFromType,
  hardCodedOrgId,
  APIURL,
  CALLBACKURL,
  UPLOADSPATH,
};
