import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import NumberRangeSelect from "components/CoreWeb/NumberRangeSelect";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

class MandatoryResourcesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <NumberRangeSelect
          placeholder="Select Number Of Mandatory Resources"
          start={this.props.start == undefined ? 0 : this.props.start}
          stop={this.props.stop == undefined ? 5 : this.props.stop}
          useValueNotIndex={true}
          fieldIdentifier={"mandatoryResources"}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default withStyles(userProfileStyles)(MandatoryResourcesSelect);
