import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Ballot from "@material-ui/icons/Ballot";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Visibility from "@material-ui/icons/Visibility";

import { orderStatusFromType } from "variables/coreWeb.jsx";

import { dataTable5 } from "variables/general.jsx";
import { labelFromType } from "variables/project.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";



class OrgInventoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    const fillButtons = [
      { color: "info", icon: Visibility },
      { color: "danger", icon: Close }
    ].map((prop, key) => {
      return (
        <Button color={prop.color} className={classes.actionButton} key={key}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Ballot />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Manage Stock Levels
                <small> - control your inventory</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTable5.dataRows.map((prop, key) => {
                  return {
                    id: key,
                    name: prop[0],
                    position: labelFromType(prop[1]),
                    status: orderStatusFromType(prop[2]),
                    age: prop[3],
                    actions: fillButtons,
                    workingFor: prop[2] != 1 ? "????" : "Coca Cola",
                    totalValue: 100,
                    productName: "product name",
                    clinicCode: "123-213l12-3",
                    systemCode: "123-2132342342l12-3",
                    salesPrice: "$50",
                    onOrder: 20
                  };
                })}
                filterable
                columns={[
                  {
                    Header: "Product Id",
                    accessor: "name"
                  },

                  {
                    Header: "Product Name",
                    accessor: "productName"
                  },
                  {
                    Header: "Stock Status",
                    accessor: "status"
                  },

                  {
                    Header: "# In Stock",
                    accessor: "totalValue"
                  },
                  {
                    Header: "On Order",
                    accessor: "onOrder"
                  },

                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(userProfileStyles)(OrgInventoryList);
