import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReduxCustomInput from "components/CoreWeb/ReduxCustomInput.jsx";
import * as actions from "actions/projectActionTypes";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class CreateServiceStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <h4>Campaign Name</h4>
            <ReduxCustomInput
              subTextLabel="(required)"
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"serviceDetails.name"}
              multiLine={1}
            />
            
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>Campaign Description</h4>
            <ReduxCustomInput
              updateAction={actions.cw.UPREDUXCUSTOMINPUT}
              fieldIdentifier={"serviceDetails.description"}
              multiLine={5}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(style)(CreateServiceStep1);
