import ReduxThunk from "redux-thunk";
import * as actions from "actions/projectActionTypes";
import { updateAnimal, deleteAnimal } from "variables/coreRequests.jsx";
import {
  updateContact,
  updateService,
  updateProduct,
  updateResource,
  deletePatient,
  deleteContact,
  deleteAddress,
  deleteService,
  deleteProduct,
  deleteResource,
  deleteMedical,
} from "./coreRequests";
import { createCustomerAddress } from "variables/coreCreateRequests";
import { changeUrl } from "variables/coreWeb.jsx";

import { paths } from "variables/projPaths";
import { createMedicalNote } from "./coreCreateRequests";

const updateAnimalWithDispatch = function(callApi, animalDetails) {
  return async function(dispatch, getState) {
    console.log("-------AnimalDeetz:" + JSON.stringify(animalDetails));
    await updateAnimal(callApi, animalDetails);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const updateCustomerWithDispatch = function(callApi, contactDetails) {
  return async function(dispatch, getState) {
    console.log("-------CustomerDeetz:" + JSON.stringify(contactDetails));
    await updateContact(callApi, contactDetails);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const updateServiceWithDispatch = function(callApi, serviceDetails) {
  return async function(dispatch, getState) {
    console.log("-------ServiceDeetz:" + JSON.stringify(serviceDetails));
    await updateService(callApi, serviceDetails);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const createAddressWithDispatch = function(callApi, addressDetails) {
  return async function(dispatch, getState) {
    console.log("-------AddressDeetz:" + JSON.stringify(addressDetails));
    await createCustomerAddress(callApi, addressDetails);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      changeUrl(
        paths.customerView + "/" + addressDetails.contactDetails.contactId
      );
      //dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const createMedicalWithDispatch = function(callApi, medicalDetails, animalId) {
  return async function(dispatch, getState) {
    console.log(
      "-------MedicalDeetz:" + JSON.stringify(medicalDetails, animalId)
    );
    await createMedicalNote(callApi, medicalDetails, animalId);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      changeUrl(paths.animalView + "/" + animalId);
      //dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const updateProductWithDispatch = function(callApi, productDetails) {
  return async function(dispatch, getState) {
    console.log("-------ProductDeetz:" + JSON.stringify(productDetails));
    await updateProduct(callApi, productDetails);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const updateResourceWithDispatch = function(callApi, resourceDetails) {
  return async function(dispatch, getState) {
    console.log("-------ResourceDeetz:" + JSON.stringify(resourceDetails));
    await updateResource(callApi, resourceDetails);
    // await dispatch({type: actions.cw.NOTI_CREATESUCCESS});
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteAnimalWithDispatch = function(callApi, selectedAnimalId) {
  return async function(dispatch, getState) {
    console.log("-------DeleteAnimalDeetz:" + JSON.stringify(selectedAnimalId));
    await deletePatient(callApi, selectedAnimalId);
    // await dispatch({ type: actions.cw.NOTI_CREATESUCCESS });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteContactWithDispatch = function(callApi, selectedCustomerId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteContactDeetz:" + JSON.stringify(selectedCustomerId)
    );
    await deleteContact(callApi, selectedCustomerId);
    // await dispatch({ type: actions.cw.NOTI_CREATESUCCESS });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteContactAddressWithDispatch = function(callApi, selectedAddressId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteContactAddressDeetz:" + JSON.stringify(selectedAddressId)
    );
    await deleteAddress(callApi, selectedAddressId);
    // await dispatch({ type: actions.cw.NOTI_CREATESUCCESS });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteServiceWithDispatch = function(callApi, selectedServiceId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteServiceDeetz:" + JSON.stringify(selectedServiceId)
    );
    await deleteService(callApi, selectedServiceId);
    // await dispatch({ type: actions.cw.NOTI_CREATESUCCESS });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteProductWithDispatch = function(callApi, selectedProductId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteProductDeetz:" + JSON.stringify(selectedProductId)
    );
    await deleteProduct(callApi, selectedProductId);
    // await dispatch({ type: actions.cw.NOTI_CREATESUCCESS });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteResourceWithDispatch = function(callApi, selectedResourceId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteResourceDeetz:" + JSON.stringify(selectedResourceId)
    );
    await deleteResource(callApi, selectedResourceId);
    // await dispatch({ type: actions.cw.NOTI_CREATESUCCESS });
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

const deleteMedicalWithDispatch = function(callApi, selectedMedicalId) {
  return async function(dispatch, getState) {
    console.log(
      "-------DeleteResourceDeetz:" + JSON.stringify(selectedMedicalId)
    );
    await deleteMedical(callApi, selectedMedicalId);
    setTimeout(function() {
      dispatch({ type: actions.cw.NOTI_CREATESUCCESS_HIDE });
    }, 3000);
  };
};

export {
  updateAnimalWithDispatch,
  updateCustomerWithDispatch,
  updateServiceWithDispatch,
  updateProductWithDispatch,
  updateResourceWithDispatch,
  deleteAnimalWithDispatch,
  deleteContactWithDispatch,
  deleteContactAddressWithDispatch,
  deleteServiceWithDispatch,
  deleteProductWithDispatch,
  deleteResourceWithDispatch,
  createAddressWithDispatch,
  createMedicalWithDispatch,
  deleteMedicalWithDispatch,
};
