import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CreateInventoryStep1 from "./WizardSteps/CreateInventoryStep1.jsx";
import CreateServiceStep2 from "components/CoreWeb/CreateServiceStep2.jsx";
import CreateServiceStep4 from "components/CoreWeb/CreateServiceStep4.jsx";
import ComingSoonStep from "components/CoreWeb/ComingSoonStep.jsx";


class CreateInventory extends React.Component {
  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Wizard
            validate
            steps={[
              { stepName: "Options", stepComponent: CreateInventoryStep1, stepId: "about" },
              
              
            ]}
            title="Add Inventory"
            subtitle="We will now add new inventory items"
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default CreateInventory;
