import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import ContactBasicOptions from "components/CoreWeb/ContactBasicOptions.jsx";


const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class CreateCustomerStep1 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <ContactBasicOptions />
        
      </div>
    );
  }
}

export default withStyles(style)(CreateCustomerStep1);
