import * as actions from 'actions/projectActionTypes';
import axios from 'axios';
import {APIURL} from "variables/project"
import genericHttpHandler from 'actions/genericHttpHandler';
import genericRespHandler from 'actions/genericRespHandler';


export function makeApiCallHandler(json, action) {
  return {type: action, payload: json};
}

export function req(apiKey,endpointUrl,data,afterActionHandler) {
  console.log("req - makeApiCall.js")
  let config = {
    headers: {
     
      'Content-Type':'application/json',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ` + apiKey
    }
  }
  
  return dispatch => {
    axios.post(
      APIURL + endpointUrl,
      data, 
      config
    )
    .then(res => { 
      return res.data;
    })
    .then(json => 
      {
        genericRespHandler(json, dispatch);
        dispatch(makeApiCallHandler(json,afterActionHandler));
        
      })
      .catch((error) => {
        if( error.response ){
           genericHttpHandler(error.response);
        }
      })
  };
}